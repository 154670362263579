<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    console.log("web version 1.0.2");
  },
};
</script>
<style src="@/assets/css/base.css"></style>
<style>
:root {
  --el-slider-height: 3px;
  --el-slider-button-size: 10px !important;
}

.el-slider__button {
  width: 12px !important;
  height: 12px !important;
  border-color: #333 !important;
}

.el-slider__bar {
  background-color: #333 !important;
}

.el-drawer__header {
  margin-bottom: 0rem !important;
  padding: 0.8rem !important;
  border-bottom: 1px solid #cccccc35;
}

.el-drawer__body {
  padding: 10px !important;
}

.myToast {
  opacity: 0;
  font-size: 0.9rem;
  color: white;
  height: 2rem;
  width: 100vw;
  position: fixed;
  bottom: 30vh;
  pointer-events: none;
  transition: all 0.3s linear;
  transform: translateY(50px);
  visibility: hidden;
}

.showToast {
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.v-contextmenu {
  border: none !important;
  border-radius: 0px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(2px);
  color: orange !important;
  padding: 5px;
  box-shadow: 0 0 15px #ccc;
}

.v-contextmenu-submenu__menus {
  max-height: 250px;
  overflow-y: auto;
}

.v-contextmenu-submenu__menus .v-contextmenu-item {
  padding-left: 5px !important;
}

.v-contextmenu-item {
  color: black !important;
  transition: all 0.2s linear !important;
  font-weight: bold;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.v-contextmenu-item--hover {
  background: orange !important;
}

.v-contextmenu-item:hover {
  background: orange !important;
}

.v-contextmenu-icon+.v-contextmenu-name {
  margin-left: 5px;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.el-dialog__footer {
  margin-top: 0.8rem !important;
}

.el-loading-mask {
  position: fixed !important;
  left: 0 !important;
  /* top: 35px !important; */
  z-index: 0 !important;
}
</style>
