<!-- 背景页面 -->
<template>
  <div class="w100 h100 oHide container">
    <div v-if="list.length == 0" class="bgItem w100 h100">
      <img
        class="w100 h100"
        src="http://localhost:12008/static/images/theme.png"
        alt=""
      />
    </div>
    <div
      class="bgItem w100 h100"
      :data-bgidx="idx"
      :style="`z-index:${item.id == currentID ? 999 : idx}`"
      v-show="
        item.id == currentID ||
        item.id == preID ||
        item.id == nextID ||
        item.id == nextShowID ||
        item.id == preShowID
      "
      :class="[
        {
          [`bgItem_${item.id}`]: true,
          now: idx == showIndex,
          ready: idx == readyIndex,
          left: idx < showIndex,
          right: idx > showIndex,
          top: idx < showIndex,
          bottom: idx > showIndex,
        },
        effect,
      ]"
      v-for="(item, idx) in list"
      :key="item.id"
    >
      <!-- 组合 -->
      <div
        :class="'w100 h100 combine ' + item.combineName"
        v-if="
          item.type == 'combine' &&
          (item.id == currentID ||
            item.id == preID ||
            (item.id == nextIDitem.id) == nextShowID ||
            item.id == preShowID)
        "
      >
        <div
          class="combineItem"
          v-for="(sub, sidx) in item.url"
          :data-no="sidx"
          :key="sub.url"
        >
          <!-- 图片 -->
          <img
            class="w100 h100"
            :class="{ [`img_${item.id}`]: true }"
            v-if="sub.type == 'img'"
            :src="'http://localhost:12008/static/images/' + sub.source"
            alt=""
          />
          <!-- 视频 -->
          <video
            class="w100 h100 video"
            :class="{ [`video_${item.id}`]: true }"
            v-if="sub.type == 'video'"
            :src="'http://localhost:12008/static/images/' + sub.source"
            loop
            :muted="mute"
            :volume="volume"
          ></video>
          <!-- 网页 -->
          <iframe
            class="w100 h100"
            :class="{ [`url_${item.id}`]: true }"
            style="border: none"
            :src="'' + sub.url"
            v-if="sub.type == 'url'"
            @load="urlLoad($event, item)"
          ></iframe>
        </div>
      </div>
      <!-- 图片 -->
      <img
        class="w100 h100"
        :class="{ [`img_${item.id}`]: true }"
        v-if="
          item.type == 'img' &&
          (item.id == currentID ||
            item.id == preID ||
            item.id == nextID ||
            item.id == nextShowID ||
            item.id == preShowID)
        "
        :src="'http://localhost:12008/static/images/' + item.url[0].source"
        alt=""
      />
      <!-- 视频 -->
      <video
        class="w100 h100 video"
        :class="{ [`video_${item.id}`]: true }"
        v-if="
          item.type == 'video' &&
          (item.id == currentID ||
            item.id == preID ||
            item.id == nextID ||
            item.id == nextShowID ||
            item.id == preShowID)
        "
        :src="'http://localhost:12008/static/images/' + item.url[0].source"
        @ended="videoEnded(idx, item)"
        :muted="mute"
        :volume="volume"
      ></video>
      <!-- 网页 -->
      <iframe
        class="w100 h100"
        :class="{ [`url_${item.id}`]: true }"
        style="border: none"
        :src="item.url[0].url"
        @load="urlLoad($event, item)"
        v-if="
          item.type == 'url' &&
          (item.id == currentID ||
            item.id == preID ||
            item.id == nextID ||
            item.id == nextShowID ||
            item.id == preShowID)
        "
      ></iframe>
    </div>
  </div>
</template>
<script>
var awaitNextTimer = null;
var nextTaskNext = null;
var awaitLoadingTimer = null;
var loadingTaskNext = null;
export default {
  name: "backgroundPage",
  data() {
    return {
      //当前页面显示类型background=壁纸，scr=屏保
      type: "background",
      //当前使用的特效
      effect: "defaultEffect",
      //特效列表
      effects: [],
      //是否静音
      mute: true,
      //列表
      list: [],
      //上次显示的
      preID: -1,
      //下一个要显示的
      nextID: -1,
      //当前播放目录ID
      folderID: -1,
      //当前显示的ID
      currentID: 0,
      //当前是否在加载网页
      loadWebState: false,
      //是否正在加载图片
      loadImageState: false,
      //音量
      volume: 0.0,
    };
  },
  methods: {
    urlLoad() {
      this.loadWebState = false;
    },
    //视频播完了
    videoEnded(idx, item) {
      if (item.folderID != this.folderID && item.folderID != -1) {
        return;
      }
      if (idx == this.showIndex) {
        this.$ws.send("broadcast.broadcastMessage", {
          type: "videoPlayEnded",
          data: "",
        });
      }
    },
    //音量发生变化
    volumeChange(val) {
      // var videoList = document.querySelectorAll(".video");
      // for (const v of videoList) {
      //   v.volume = Number(val) / 100;
      // }
      this.volume = Number(val) / 100;
    },
    //是否静音
    muteChange(val) {
      this.mute = val == 1;
    },
    //特效选择发生变化
    effectChange(val) {
      if (typeof val == "string") {
        this.effects = JSON.parse(val);
      } else {
        this.effects = val;
      }
    },
    //播放列表发生变化
    playListChange(val) {
      if (typeof val == "string") {
        this.list = JSON.parse(val);
      } else {
        this.list = val;
      }
      if (this.list.length > 0) {
        this.folderID = this.list[0].folderID;
      }
    },
    //屏保列表发生变化
    scrListChange(val) {
      if (this.type != "scr") {
        return;
      }
      if (typeof val == "string") {
        this.list = JSON.parse(val);
      } else {
        this.list = val;
      }
      if (this.list.length > 0) {
        this.folderID = this.list[0].folderID;
      }
    },
    //刷新背景页面
    refshBackground() {
      location.reload();
    },
    //播放第几张发生变化
    async playIdxChange(id) {
      this.loadWebState = true;
      if (this.effects.length == 0) {
        this.effect = "";
      } else {
        let eidx = Math.floor(Math.random() * this.effects.length);
        this.effect = this.effects[eidx];
      }
      //把当前这个要显示的显示出来，就当它是上次显示的，这样切换才会有动画
      this.nextID = id;

      //清除上次等待加载
      clearInterval(awaitNextTimer);
      if (nextTaskNext != null) {
        // console.log("清空上次等待");
        nextTaskNext(false);
      }
      //等待本次要显示的内容加载出来
      let next = await new Promise((resolve) => {
        nextTaskNext = resolve;
        let el;
        awaitNextTimer = setInterval(() => {
          el = document.querySelector(`.bgItem_${id}`);
          // console.log(`等待${id}`);
          if (el == null) {
            return;
          }
          clearInterval(awaitNextTimer);
          nextTaskNext = null;
          resolve(true);
        }, 50);
      });
      // console.log(`结束等待${id},${next}`);
      if (!next) {
        return;
      }

      //非本次要播放的都暂停
      let vList = document.querySelectorAll(`.bgItem:not(.video_${id}) video`);
      for (let i = 0; i < vList.length; i++) {
        try {
          vList[i].pause();
        } catch (ex) {
          console.log(ex);
        }
      }
      const target = await this.getItem(id);
      if (target == null) {
        // console.log("获取不到壁纸");
        return;
      }
      //视频
      if (target.type == "video") {
        //清除上次等待的视频加载
        clearInterval(awaitLoadingTimer);
        if (loadingTaskNext != null) {
          loadingTaskNext(false);
        }
        next = await new Promise((resolve) => {
          loadingTaskNext = resolve;
          awaitLoadingTimer = setInterval(() => {
            vList = document.querySelectorAll(`.video_${id}`);
            if (vList == null || vList.length == 0) {
              return;
            }
            clearInterval(awaitLoadingTimer);
            loadingTaskNext = null;
            resolve(true);
          }, 50);
        });
        if (!next) {
          return;
        }
        for (let i = 0; i < vList.length; i++) {
          try {
            vList[i].currentTime = 0;
            vList[i].play();
          } catch (ex) {
            console.log(ex);
          }
        }
      } else if (target.type == "url") {
        //清除上次等待的网页加载
        clearInterval(awaitLoadingTimer);
        if (loadingTaskNext != null) {
          loadingTaskNext(false);
        }
        let c = 0;
        next = await new Promise((resolve) => {
          loadingTaskNext = resolve;
          awaitLoadingTimer = setInterval(() => {
            c++;
            if (c > 200) {
              this.$toast.show(`该链接加载超时`, 10000);
              resolve(false);
              return;
            }
            vList = document.querySelectorAll(`.url_${id}`);
            if (vList == null || vList.length == 0) {
              return;
            }
            if (this.loadWebState) {
              return;
            }
            clearInterval(awaitLoadingTimer);
            loadingTaskNext = null;
            resolve(true);
          }, 50);
        });

        if (!next) {
          return;
        }
      } else if (target.type == "img") {
        //清除上次等待的图片加载
        clearInterval(awaitLoadingTimer);
        if (loadingTaskNext != null) {
          loadingTaskNext(false);
        }
        next = await new Promise((resolve) => {
          loadingTaskNext = resolve;
          awaitLoadingTimer = setInterval(() => {
            vList = document.querySelectorAll(`.img_${id}`);
            if (vList == null || vList.length == 0) {
              return;
            }
            if (this.loadImageState) {
              return;
            }
            clearInterval(awaitLoadingTimer);
            loadingTaskNext = null;
            resolve(true);
          }, 50);
        });
      }

      this.preID = this.currentID;
      this.$nextTick(() => {
        this.currentID = id;
        //上一个隐藏掉
        setTimeout(() => {
          this.preID = -1;
          this.nextID = -1;
        }, 2000);
      });
    },
    //根据ID获取列表里的壁纸
    async getItem(id) {
      for (const item of this.list) {
        if (id == item.id) {
          return item;
        }
      }
      return null;
    },
  },
  computed: {
    //准备显示第几个
    readyIndex() {
      let readyIdx = this.showIndex + 1;
      if (readyIdx >= this.list.length) {
        readyIdx = 0;
      }
      return readyIdx;
    },
    //当前显示第几个
    showIndex() {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == this.currentID) {
          return i;
        }
      }
      return 0;
    },
    nextShowID() {
      let idx = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == this.currentID) {
          idx = i + 1;
        }
      }
      idx = idx >= this.list.length ? 0 : idx;
      return this.list[idx].id;
    },
    preShowID() {
      let idx = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == this.currentID) {
          idx = i - 1;
        }
      }
      idx = idx < 0 ? this.list.length - 1 : idx;
      return this.list[idx].id;
    },
  },
  async mounted() {
    //订阅广播消息
    {
      await this.$ws.send("broadcast.subscribe", {});
      this.$ws.subscribe("broadcast.subscribe", async (res) => {
        const r = res.data;
        if (r == null || r.type == null) {
          return;
        }
        if (typeof this[r.type] == "function") {
          this[r.type](r.data);
        }
      });
      //准备就绪
      this.$ws.send("appState.initBackground", {});
    }
    //屏保逻辑
    scr: {
      if (this.$route.query.type) {
        this.type = this.$route.query.type;
      } else {
        break scr;
      }
      if (this.type != "scr") {
        break scr;
      }
      //发送初始化完成给app ,app再发送scrListChange 传递屏保列表过来、playIdxChange
      this.$ws.send("broadcast.broadcastMessage", {
        type: "scrInit",
        data: null,
      });
    }
  },
  beforeUnmount() {},
};
</script>
<style scoped src="@/assets/css/background.css"></style>
<style scoped src="@/assets/css/effect.css"></style>
<style scoped src="@/assets/css/combine.css"></style>
<style scoped src="@/assets/css/animation.css"></style>
