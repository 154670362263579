<template>
  <div id="appPage" class="col w100 h100">
    <!-- 背景 -->
    <div
      id="background"
      :style="
        'background-image:url(http://localhost:12008/static/images/' +
        playSettings.theme +
        ')'
      "
      class="fixed w100 h100"
    ></div>
    <!-- 顶部 -->
    <div id="head" class="row between vCenter" @dblclick="setWindowState(2)">
      <div id="headLeft" class="row vCenter">
        <!-- logo -->
        <img id="logo" src="/favicon.ico" @click="gotoHome" />
        <!-- 软件标题 -->
        <div id="appName" class="pointer">
          <div
            class="menuItem"
            :class="{ menuItemOn: showPage == 'storeWallpaper' }"
            @click="showStore"
          >
            在线壁纸
          </div>
          <div
            class="menuItem"
            :class="{ menuItemOn: showPage == 'myWallpaper' }"
            @click="gotoHome"
          >
            我的壁纸
          </div>
          <div
            class="menuItem"
            :class="{ menuItemOn: showPage == 'myGame' }"
            @click="gotoMyGame"
          >
            我的游戏
          </div>
        </div>
      </div>
      <div id="headRight" class="row vCenter">
        <!-- 下载任务 -->
        <div
          class="headBtn"
          id="top_downloadBtn"
          @click="
            downloadData.show = !downloadData.show;
            downloadData.selectType = 0;
          "
        >
          <div
            class="downloadState"
            :class="{ downloadState0: downloadCountInfo[0] > 0 }"
          ></div>
          <el-icon>
            <Download />
          </el-icon>
        </div>
        <!-- 联系我们 -->
        <div class="headBtn" id="top_aboutBtn">
          <!-- <el-popover placement="top-start" title="联系我们" :width="500" trigger="click"> -->
          <el-popover
            placement="top-start"
            title="联系我们"
            :width="200"
            trigger="click"
          >
            <template #reference>
              <el-icon>
                <Service />
              </el-icon>
            </template>
            <div id="qrcodeList">
              <!-- <img class="qrcode" src="@/assets/images/qq.png" />
              <img class="qrcode" src="@/assets/images/wechat.png" /> -->
              <img class="qrcode" src="@/assets/images/wechat2.png" />
            </div>
          </el-popover>
        </div>
        <!-- 更换皮肤 @click="setTheme" -->
        <div class="headBtn" id="top_themeBtn">
          <el-popover title="" :width="700" trigger="click">
            <template #reference>
              <el-icon>
                <Brush />
              </el-icon>
            </template>
            <div class="row vCenter" id="themeListHead">
              <span>更换皮肤</span>
              <el-link
                type="danger"
                style="margin-left: 0.5rem"
                @click="setTheme('')"
              >
                自定义
              </el-link>
            </div>
            <div id="themeList">
              <img
                class="themeImg"
                v-for="item in themeList"
                @click="setTheme(item)"
                :key="item"
                :src="'http://localhost:12008/static/images/' + item"
                alt=""
              />
            </div>
          </el-popover>
        </div>
        <!-- 设置 -->
        <div class="headBtn" id="top_settingBtn" @click="showSettings = true">
          <el-icon>
            <Setting />
          </el-icon>
        </div>
        <!-- 最小化 -->
        <!-- <div class="headBtn" @click="setWindowState(1)">
          <el-icon>
            <Minus />
          </el-icon>
        </div> -->
        <!-- 全屏 -->
        <div class="headBtn" @click="setWindowState(2)">
          <el-icon>
            <FullScreen />
          </el-icon>
        </div>
        <!-- 关闭 -->
        <div class="headBtn" id="top_miniwinBtn" @click="setWindowState(3)">
          <el-icon>
            <Close />
          </el-icon>
        </div>
      </div>
    </div>
    <!-- 中间 -->
    <div class="w100 h100 oHide" id="middle" ref="middle">
      <!-- 我的壁纸 -->
      <div
        id="content"
        ref="content"
        class="page w100 h100"
        :class="{ showPage: showPage == 'myWallpaper', oHideT: view.show }"
      >
        <!-- 壁纸浏览 -->
        <div v-show="view.show" id="viewPanel">
          <el-icon id="closeViewPanelBtn" @click="view.show = false">
            <CircleCloseFilled />
          </el-icon>
          <el-icon id="viewPreBtn" @click="nextView(-1)">
            <ArrowLeftBold />
          </el-icon>
          <el-icon id="viewNextBtn" @click="nextView(1)">
            <ArrowRightBold />
          </el-icon>

          <!-- 组合 -->
          <div
            :class="'w100 h100 combine ' + view.item.combineName"
            v-if="view.item.type == 'combine'"
          >
            <div
              class="combineItem"
              v-for="(sub, sidx) in view.item.url"
              :data-no="sidx"
              :key="sub.url"
            >
              <!-- 图片 -->
              <img
                class="w100 h100"
                v-if="sub.type == 'img'"
                :src="'http://localhost:12008/static/images/' + sub.source"
                alt=""
              />
              <!-- 视频 -->
              <video
                class="w100 h100 video"
                v-if="sub.type == 'video'"
                :src="'http://localhost:12008/static/images/' + sub.source"
                :muted="playSettings.mute"
              ></video>
              <!-- 网页 -->
              <iframe
                class="w100 h100"
                style="border: none"
                :src="'' + sub.url"
                v-if="sub.type == 'url'"
              ></iframe>
            </div>
          </div>
          <!-- 图片 -->
          <img
            class="w100 h100"
            v-if="view.item.type == 'img'"
            :src="
              'http://localhost:12008/static/images/' + view.item.url[0].source
            "
            alt=""
          />
          <!-- 视频 -->
          <video
            class="w100 h100 video"
            controls
            v-if="view.item.type == 'video' && view.show"
            :src="
              'http://localhost:12008/static/images/' + view.item.url[0].source
            "
          ></video>
          <!-- 网页 -->
          <iframe
            class="w100 h100"
            style="border: none"
            :src="view.item.url[0].url"
            v-if="view.item.type == 'url'"
          ></iframe>
        </div>

        <!-- 文件夹 -->
        <div
          class="item col"
          v-for="(item, idx) in folders"
          v-contextmenu:foldercontextmenu
          @contextmenu="
            contextMenu.folder.item = item;
            contextMenu.folder.idx = idx;
          "
          :key="idx"
          :class="{
            selectMode: selectFolderMode == true,
            onSelect: selectFolderDict[item.folderID] == true,
          }"
          v-show="showType == 'folder'"
          @click="getWallpaperByFolderID(item)"
        >
          <!-- 文件夹 -->
          <div class="folder col vCenter hCenter">
            <!-- 文件夹图标 -->
            <el-icon size="45" color="#fff">
              <Folder />
            </el-icon>
            <div class="folderWPCount" v-html="item.wallpaperCount"></div>
            <!-- 文件夹名称 -->
            <div class="folderName" v-html="item.folderName"></div>
          </div>
        </div>

        <!-- 文件夹右键菜单 -->
        <v-contextmenu ref="foldercontextmenu">
          <v-contextmenu-item
            v-if="selectFolderMode != true"
            @click="outputDirectory"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">导出分类</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode != true"
            @click="inoutSelectFolderMode(true)"
          >
            <el-icon class="v-contextmenu-icon">
              <Pointer />
            </el-icon>
            <div class="v-contextmenu-name">选择模式</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode == true"
            @click="inoutSelectFolderMode(false)"
          >
            <el-icon class="v-contextmenu-icon">
              <MoreFilled />
            </el-icon>
            <div class="v-contextmenu-name">退出选择</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode == true"
            @click="outputSelectFolder"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">导出已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode == true"
            @click="removeSelectFolder"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderDelete />
            </el-icon>
            <div class="v-contextmenu-name">删除已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode != true"
            @click="remarkFolder"
          >
            <el-icon class="v-contextmenu-icon">
              <Folder />
            </el-icon>
            <div class="v-contextmenu-name">重命名</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectFolderMode != true"
            @click="deleteFolder"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderDelete />
            </el-icon>
            <div class="v-contextmenu-name">删除分类</div>
          </v-contextmenu-item>
        </v-contextmenu>

        <!-- 添加文件夹 -->
        <div
          class="item col"
          id="middel_addFolderBtn"
          @click="showAddFolderForm"
          v-if="showType == 'folder' && selectFolderMode == false"
        >
          <div class="folder col vCenter hCenter">
            <!-- 添加文件夹图标 -->
            <el-icon size="45" color="#fff">
              <FolderAdd />
            </el-icon>
            <div class="folderName">添加分类</div>
          </div>
        </div>

        <!-- 壁纸 -->
        <div
          v-contextmenu:wallpapercontextmenu
          @contextmenu="
            contextMenu.wallpaper.item = item;
            contextMenu.wallpaper.idx = idx;
          "
          v-for="(item, idx) in wallpapers"
          :key="item.id"
          v-show="showType == 'wallpaper'"
          class="wallpaperItem"
          :class="{
            [item.combineName]: item.type == 'combine',
            combine: item.type == 'combine',
            selectMode: selectWallpaperMode == true,
            onSelect: selectWallpaperDict[item.id] != null,
          }"
        >
          <div
            class="wallpaper row vCenter hCenter"
            :class="{ combineItem: item.type == 'combine' }"
            v-for="(img, c_idx) in item.url"
            @click="selectWallpaper(item, idx)"
            :data-no="c_idx"
            :key="c_idx"
          >
            <el-icon
              v-if="item.type == 'url' && item.cover == ''"
              size="35"
              color="#fff"
              style="margin-top: -0.5rem"
            >
              <Link />
            </el-icon>
            <div
              class="wallpaperName"
              v-if="item.type == 'url' && item.cover == ''"
              v-html="item.name"
            ></div>

            <img
              v-if="item.type == 'img' || item.type == 'video'"
              class="wallpaperImg"
              :src="'http://localhost:12008/static/images/' + img.url"
            />
            <img
              v-if="item.type == 'url' && item.cover != ''"
              class="wallpaperImg"
              :src="'http://localhost:12008/static/images/' + item.cover"
            />

            <el-icon
              class="warning"
              @click.stop="showWarning(item)"
              v-if="item.type == 'url'"
            >
              <WarningFilled />
            </el-icon>
          </div>
        </div>

        <!-- 壁纸右键文件菜单 -->
        <v-contextmenu ref="wallpapercontextmenu">
          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="viewWallpaper"
          >
            <el-icon class="v-contextmenu-icon">
              <View />
            </el-icon>
            <div class="v-contextmenu-name">预览壁纸</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="outputOneWallpaper(false)"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">快速导出</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="outputOneWallpaper(true)"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">导出壁纸</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="inoutSelectWallpaperMode(true)"
          >
            <el-icon class="v-contextmenu-icon">
              <Pointer />
            </el-icon>
            <div class="v-contextmenu-name">选择模式</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectWallpaperMode == true"
            @click="inoutSelectWallpaperMode(false)"
          >
            <el-icon class="v-contextmenu-icon">
              <MoreFilled />
            </el-icon>
            <div class="v-contextmenu-name">退出选择</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectWallpaperMode == true"
            @click="outputSelectWallpaper"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">导出已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectWallpaperMode == true"
            @click="removeSelectWallpaper"
          >
            <el-icon class="v-contextmenu-icon">
              <DocumentDelete />
            </el-icon>
            <div class="v-contextmenu-name">删除已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="openFilePath"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderOpened />
            </el-icon>
            <div class="v-contextmenu-name">文件路径</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectWallpaperMode != true"
            @click="deleteWallpaper"
          >
            <el-icon class="v-contextmenu-icon">
              <Delete />
            </el-icon>
            <div class="v-contextmenu-name">删除壁纸</div>
          </v-contextmenu-item>
        </v-contextmenu>

        <!-- 添加壁纸 -->
        <div
          class="wallpaperItem col"
          @click="showAddWallpaperForm"
          v-if="showType == 'wallpaper' && selectWallpaperMode == false"
        >
          <div class="wallpaper col vCenter hCenter">
            <!-- 添加图标 -->
            <el-icon size="45" color="#fff">
              <CameraFilled />
            </el-icon>
            <div class="folderName">添加壁纸</div>
          </div>
        </div>
      </div>
      <!-- 壁纸市场 -->
      <div
        id="store"
        class="page row w100 h100"
        :class="{ showPage: showPage == 'storeWallpaper' }"
      >
        <div id="typeListPanel" class="h100 shrink">
          <div
            class="storeWallpaperType"
            @click="selectStoreType(type)"
            :class="{ typeActive: store.selectTypeID == type.ID }"
            v-for="type in store.typeList"
            :key="type.ID"
          >
            <span>{{ type.typeName }}</span>
          </div>
        </div>
        <div
          id="storeWallpaperPanel"
          class="full w100 h100 shrink"
          v-infinite-scroll="storeNext"
          :infinite-scroll-distance="250"
          :infinite-scroll-disabled="store.loadData"
          :infinite-scroll-immediate="true"
        >
          <div id="storeWallpaperList">
            <!-- this.$serws.send("wallpaper.download", { ID: wallpaper.ID }); -->
            <div
              class="storeWallpaperItem"
              data-id="item.ID"
              @contextmenu="showStoreMenuEvent(item)"
              v-for="(item, idx) in store.list"
              :key="item.ID"
              v-contextmenu:contextmenu
            >
              <!-- <img :src="store.imgServer + item.cover" alt="" /> -->
              <el-image
                :src="store.imgServer + item.cover"
                class="w100 h100"
                :initial-index="idx"
                :previewSrcList="storePreviewSrcList"
              >
                <template #error>
                  <div class="row vCenter hCenter">
                    <el-text class="mx-1" type="info">加载失败</el-text>
                  </div>
                </template>
                <template #placeholder>
                  <div class="row vCenter hCenter">
                    <el-text class="mx-1" type="info">图片加载中..</el-text>
                  </div>
                </template>
              </el-image>
              <el-icon
                class="warning"
                @click.stop="showWarning(item)"
                v-if="item.type == 'url'"
              >
                <WarningFilled />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- 我的游戏 -->
      <div
        id="game"
        class="page w100 h100"
        :class="{ showPage: showPage == 'myGame' }"
      >
        <!-- 游戏右键菜单 -->
        <v-contextmenu ref="gamecontextmenu">
          <v-contextmenu-item
            v-if="
              selectGameMode == false &&
              contextMenu.game.item != null &&
              gvdm.tops[contextMenu.game.item.ID] == null
            "
            @click="setTopGame"
          >
            <el-icon class="v-contextmenu-icon">
              <StarFilled />
            </el-icon>
            <div class="v-contextmenu-name">游戏置顶</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="
              selectGameMode == false &&
              contextMenu.game.item != null &&
              gvdm.tops[contextMenu.game.item.ID] != null
            "
            @click="cancelTopGame"
          >
            <el-icon class="v-contextmenu-icon">
              <Star />
            </el-icon>
            <div class="v-contextmenu-name">取消置顶</div>
          </v-contextmenu-item>

          <v-contextmenu-item
            v-if="selectGameMode == false"
            @click="inoutSelectGameMode(true)"
          >
            <el-icon class="v-contextmenu-icon">
              <Pointer />
            </el-icon>
            <div class="v-contextmenu-name">选择模式</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == true"
            @click="inoutSelectGameMode(false)"
          >
            <el-icon class="v-contextmenu-icon">
              <MoreFilled />
            </el-icon>
            <div class="v-contextmenu-name">退出选择</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == true"
            @click="outputSelectGame"
          >
            <el-icon class="v-contextmenu-icon">
              <SoldOut />
            </el-icon>
            <div class="v-contextmenu-name">导出已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == true"
            @click="removeSelectGame"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderDelete />
            </el-icon>
            <div class="v-contextmenu-name">删除已选</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == false"
            @click="deleteGame"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderDelete />
            </el-icon>
            <div class="v-contextmenu-name">删除游戏</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == false"
            @click="openGameFilePath"
          >
            <el-icon class="v-contextmenu-icon">
              <FolderOpened />
            </el-icon>
            <div class="v-contextmenu-name">文件路径</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == false"
            @click="renewGameNewCover"
          >
            <el-icon class="v-contextmenu-icon">
              <PictureFilled />
            </el-icon>
            <div class="v-contextmenu-name">修改封面</div>
          </v-contextmenu-item>
          <v-contextmenu-item
            v-if="selectGameMode == false"
            @click="gameForm.showRename = true"
          >
            <el-icon class="v-contextmenu-icon">
              <Folder />
            </el-icon>
            <div class="v-contextmenu-name">重命名</div>
          </v-contextmenu-item>
        </v-contextmenu>

        <div
          v-contextmenu:gamecontextmenu
          @contextmenu="
            contextMenu.game.item = g;
            contextMenu.game.idx = idx;
          "
          class="gameItem"
          :class="{
            selectMode: selectGameMode == true,
            onSelect: selectGameDict[g.ID] != null,
          }"
          @click="gameClickEvent(g, idx)"
          v-for="(g, idx) in viewGameList"
          :key="g.ID"
        >
          <el-icon class="gameTopIcon" v-if="gvdm.tops[g.ID] != null">
            <StarFilled />
          </el-icon>
          <img
            v-if="g.gameCover == 'game.jpg'"
            class="gameCover default w100 h100"
            src="http://localhost:12008/static/images/game.png"
            alt=""
          />
          <img
            v-else
            class="gameCover w100 h100"
            :src="'http://localhost:12008/static/games/' + g.gameCover"
            alt=""
          />
          <div class="gameName w100" v-html="g.gameName"></div>
        </div>
        <div
          class="gameItem col"
          id="middel_addGameBtn"
          @click="selectGame"
          v-if="selectGameMode == false"
        >
          <div class="col vCenter hCenter">
            <!-- 添加游戏图标 -->
            <el-icon size="45" color="#fff">
              <DocumentAdd />
            </el-icon>
            <div class="gameName w100">添加游戏</div>
          </div>
        </div>
      </div>
      <!-- 商店壁纸右键菜单 -->
      <v-contextmenu ref="contextmenu" :boundingElement="$refs.middle">
        <v-contextmenu-item @click="setWallpaper">设为壁纸</v-contextmenu-item>
        <v-contextmenu-submenu title="存到我的">
          <v-contextmenu-item
            v-for="f in folders"
            :key="f.folderID"
            @click="storeSaveToFolder(f)"
          >
            <el-icon class="v-contextmenu-icon">
              <Folder />
            </el-icon>
            <div class="v-contextmenu-name">
              {{ f.folderName }}
            </div>
          </v-contextmenu-item>
        </v-contextmenu-submenu>
        <v-contextmenu-item @click="downloadWallpaper"
          >下载壁纸</v-contextmenu-item
        >
        <v-contextmenu-item>关闭菜单</v-contextmenu-item>
      </v-contextmenu>

      <!-- 下载列表 -->
      <div
        id="downloadListPage"
        class="row w100 h100"
        :class="{ showDownloadList: downloadData.show }"
      >
        <div id="downloadTypeList">
          <div
            class="downloadTypeItem"
            @click="downloadData.selectType = 0"
            :class="{ typeActive: downloadData.selectType == '0' }"
          >
            下载中（{{ downloadCountInfo[0] }}）
          </div>
          <div
            class="downloadTypeItem"
            @click="downloadData.selectType = 1"
            :class="{ typeActive: downloadData.selectType == '1' }"
          >
            已完成（{{ downloadCountInfo[1] }}）
          </div>
          <div
            class="downloadTypeItem"
            @click="downloadData.selectType = 2"
            :class="{ typeActive: downloadData.selectType == '2' }"
          >
            错误/取消（{{ downloadCountInfo[2] }}）
          </div>
        </div>
        <div id="downloadListPanel" class="full col w100 h100">
          <div
            class="downloadItem shrink"
            v-for="d in downloadData.list"
            v-show="d.state == downloadData.selectType"
            :key="d.id"
          >
            <div class="downloadHead row between">
              <span class="downloadTitle" v-html="d.source.name"></span>
              <div class="row vCenter">
                <div class="downloadTaskMsg" v-html="d.message"></div>
                <el-icon
                  class="cancelDownloadBtn"
                  v-if="d.state == 0"
                  @click="cancelDownload(d)"
                >
                  <CloseBold />
                </el-icon>
                <el-link
                  v-if="d.state == 1"
                  type="primary"
                  @click="openOtherPath(d.source.savePath)"
                >
                  打开文件夹
                </el-link>
              </div>
            </div>
            <div class="downloadFileItem col" v-if="d.source != null">
              <div class="downloadFileName row between">
                <span v-html="d.source.name"></span>
                <div class="stateMsg" v-html="d.source.stateMsg"></div>
              </div>
              <el-progress
                :percentage="
                  ((d.source.count / d.source.total) * 100).toFixed(0)
                "
                :color="d.state == 0 ? '#09f' : d.state == 1 ? 'green' : 'red'"
              />
            </div>
            <div class="downloadFileItem col" v-if="d.cover != null">
              <div class="downloadFileName row between">
                <span v-html="d.cover.name"></span>
                <div class="stateMsg" v-html="d.cover.stateMsg"></div>
              </div>
              <el-progress
                :percentage="((d.cover.count / d.cover.total) * 100).toFixed(0)"
                :color="d.state == 0 ? '#09f' : d.state == 1 ? 'green' : 'red'"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 壁纸浏览 -->
      <div
        id="previewPage"
        @click="store.preview = false"
        v-show="store.preview"
        class="w100 h100"
      >
        <el-icon id="closePreviewBtn" @click="store.preview = false">
          <CloseBold />
        </el-icon>
        <img :src="store.previewImg" alt="" />
      </div>
    </div>

    <!-- 底部 -->
    <div id="foot" class="col shrink">
      <!-- 进度条 -->
      <!-- <el-progress
        :percentage="50"
        text-inside=""
        stroke-width="2"
        striped
        striped-flow
        duration="100"
      >
      </el-progress> -->
      <!-- 壁纸控制面板 -->
      <div
        v-show="showPage != 'myGame'"
        class="row between shrink full"
        id="controlPanel"
      >
        <!-- 控制面板左边 -->
        <div id="footLeft" class="row vCenter hCenter">
          <!-- 播放声音、静音按钮 -->
          <div class="footBtn" id="volumePanel">
            <!-- 播放声音图标 -->
            <el-icon
              size="20"
              @click="mute(1)"
              v-if="playSettings.volume > 0 && playSettings.mute == 0"
            >
              <Bell />
            </el-icon>
            <!-- 静音图标 -->
            <el-icon size="20" @click="mute(0)" v-else>
              <MuteNotification />
            </el-icon>
            <div id="volume">
              <div class="slider-demo-block">
                <el-slider
                  size="small"
                  @change="saveConfig"
                  v-model="playSettings.volume"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 控制面板中间 -->
        <div id="footCenter" class="row vCenter">
          <!-- 上一张 -->
          <div class="footBtn" @click="prev">
            <el-icon size="23">
              <ArrowLeft />
            </el-icon>
            <span class="tipText">上一张</span>
          </div>
          <!-- 播放、暂停 -->
          <div
            class="footBtn"
            id="foot_autoplayBtn"
            @click="play"
            title="是否自动切换"
          >
            <!-- 暂停按钮 -->
            <el-icon size="23" v-if="playSettings.play == 1">
              <VideoPause />
            </el-icon>
            <!-- 播放按钮 -->
            <el-icon size="23" v-else>
              <VideoPlay />
            </el-icon>
          </div>
          <!-- 下一张 -->
          <div class="footBtn" @click="next">
            <span class="tipText">下一张</span>
            <el-icon size="23">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
        <!-- 控制面板右边 -->
        <div id="footRight" class="row vCenter hCenter">
          <!-- 循环模式按钮 -->
          <div
            class="footBtn"
            id="foot_modeBtn"
            @click="nextMode"
            title="循环模式"
          >
            <!-- 单个循环 -->
            <el-icon size="20" v-if="playSettings.loopMode == 'oneLoop'">
              <Refresh />
            </el-icon>
            <!-- 列表循环 -->
            <el-icon size="20" v-else-if="playSettings.loopMode == 'listLoop'">
              <Switch />
            </el-icon>
            <!-- 随机 -->
            <el-icon size="20" v-else>
              <Sort />
            </el-icon>
            <span
              class="tipText loopType"
              v-if="playSettings.loopMode == 'oneLoop'"
              >单个循环</span
            >
            <span
              class="tipText loopType"
              v-else-if="playSettings.loopMode == 'listLoop'"
              >列表循环</span
            >
            <span class="tipText loopType" v-else>随机播放</span>
          </div>
          <!-- 间隔时间设置按钮 -->
          <div class="footBtn" id="foot_timeBtn" title="切换壁纸间隔时间">
            <el-popover
              placement="top"
              title="壁纸切换间隔时间(秒)"
              :width="200"
              trigger="click"
            >
              <el-checkbox v-model="playSettings.timeApplyVideo">
                视频跟随自动切换
              </el-checkbox>
              <el-input
                v-model="playSettings.time"
                type="number"
                min="2"
                max="100000000"
                placeholder="输入间隔时间，单位/秒"
                @change="saveConfig"
              />
              <template #reference>
                <el-icon size="20">
                  <Timer />
                </el-icon>
              </template>
            </el-popover>
          </div>
          <div class="footBtn" id="foot_surplusTime">
            {{ surplusTime }}
          </div>
          <!-- 效果选择按钮 -->
          <div
            class="footBtn"
            id="foot_effctBtn"
            @click="
              showSettings = true;
              selectdTab = '切换效果';
            "
            title="切换壁纸效果"
          >
            <el-icon size="20">
              <ScaleToOriginal />
            </el-icon>
          </div>
        </div>
      </div>
      <div
        v-show="showPage == 'myGame'"
        id="gameSearchPanel"
        class="w100 row vCenter hCenter"
        style="height: 100%"
      >
        <input
          id="gameSearchInput"
          type="text"
          placeholder="输入游戏名称搜索本地游戏，清空显示全部"
          @compositionstart="compositionState = true"
          @compositionend="compositionState = false"
          v-model="gameSearchTextInput"
          @input="inputGameSearchTextEvent"
        />
      </div>
    </div>

    <!-- 导入壁纸密码对话框 -->
    <el-dialog
      v-model="input.showPwdDialog"
      title="导入密码验证"
      width="260"
      center
    >
      <el-input
        v-model="input.password"
        type="password"
        placeholder="请输入密码"
        show-password
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="hideInput"> 取消 </el-button>
          <el-button type="primary" @click="inputFile"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 导入game密码对话框 -->
    <el-dialog
      v-model="inputGame.showPwdDialog"
      title="导入密码验证"
      width="260"
      center
    >
      <el-input
        v-model="inputGame.password"
        type="password"
        placeholder="请输入密码"
        show-password
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="hideInputGame"> 取消 </el-button>
          <el-button type="primary" @click="inputGameFile"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 导出壁纸对话框 -->
    <el-dialog
      v-model="output.showOutputDialog"
      :title="`导出壁纸(共${output.outputWallpaper.length}张)`"
      width="60%"
    >
      <el-form label-width="auto">
        <el-form-item label="壁纸列表">
          <div id="outputListPanel">
            <div
              v-for="(item, idx) in output.outputWallpaper"
              :key="item.id"
              :class="
                'wallpaperItem ' +
                (item.type == 'combine' ? 'combine ' + item.combineName : '')
              "
            >
              <div class="deletePanel row vCenter hCenter w100 h100">
                <el-icon
                  @click="output.outputWallpaper.splice(idx, 1)"
                  class="deleteOutputItemIcon"
                  size="2rem"
                >
                  <Delete />
                </el-icon>
              </div>
              <div
                :class="
                  'wallpaper row vCenter hCenter ' +
                  (item.type == 'combine' ? 'combineItem ' : '')
                "
                v-for="(img, c_idx) in item.url"
                :data-no="c_idx"
                :key="c_idx"
              >
                <el-icon
                  v-if="
                    item.type == 'url' &&
                    (item.cover == '' || item.cover == null)
                  "
                  size="35"
                  color="#fff"
                  style="margin-top: -0.5rem"
                >
                  <Link />
                </el-icon>
                <div
                  class="wallpaperName"
                  v-if="
                    item.type == 'url' &&
                    (item.cover == '' || item.cover == null)
                  "
                  v-html="item.name"
                ></div>
                <img
                  v-else
                  class="wallpaperImg"
                  :src="
                    'http://localhost:12008/static/images/' +
                    (item.type == 'url' ? item.cover : img.url)
                  "
                />
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="文件署名">
          <el-input
            v-model="output.tag"
            style="width: 240px"
            type="text"
            maxlength="32"
            placeholder="导入时会看到（可空）"
          />
        </el-form-item>
        <el-form-item label="访问密码">
          <el-input
            v-model="output.password"
            style="width: 240px"
            type="password"
            placeholder="输入访问密码（可空）"
            show-password
          />
        </el-form-item>
        <el-form-item label="导出路径">
          <el-input
            v-model="output.savePath"
            @click="selectOutputPath"
            disabled
          >
            <template #append>
              <el-button @click="selectOutputPath" type="primary">
                选择路径
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="output.showOutputDialog = false"> 取消 </el-button>
          <el-button type="primary" @click="startOutput"> 导出 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 导出游戏对话框 -->
    <el-dialog
      v-model="outputGame.showOutputDialog"
      :title="`导出游戏(共${outputGame.outputGame.length}个)`"
      width="60%"
    >
      <el-form label-width="auto">
        <el-form-item label="游戏列表">
          <div class="outputListPanel">
            <div
              v-for="(item, idx) in outputGame.outputGame"
              :key="item.id"
              class="gameItem"
            >
              <div class="deletePanel row vCenter hCenter w100 h100">
                <el-icon
                  @click="outputGame.outputGame.splice(idx, 1)"
                  class="deleteOutputItemIcon"
                  size="2rem"
                >
                  <Delete />
                </el-icon>
              </div>
              <img
                v-if="item.gameCover == 'game.jpg'"
                class="gameCover default w100 h100"
                src="http://localhost:12008/static/images/game.png"
                alt=""
              />
              <img
                v-else
                class="gameCover w100 h100"
                :src="'http://localhost:12008/static/games/' + item.gameCover"
                alt=""
              />
              <div class="gameName w100" v-html="item.gameName"></div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="文件署名">
          <el-input
            v-model="outputGame.tag"
            style="width: 240px"
            type="text"
            maxlength="32"
            placeholder="导入时会看到（可空）"
          />
        </el-form-item>
        <el-form-item label="访问密码">
          <el-input
            v-model="outputGame.password"
            style="width: 240px"
            type="password"
            placeholder="输入访问密码（可空）"
            show-password
          />
        </el-form-item>
        <el-form-item label="导出路径">
          <el-input
            v-model="outputGame.savePath"
            @click="selectGameOutputPath"
            disabled
          >
            <template #append>
              <el-button @click="selectGameOutputPath" type="primary">
                选择路径
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="outputGame.showOutputDialog = false">
            取消
          </el-button>
          <el-button type="primary" @click="startOutputGame"> 导出 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加文件夹对话框 -->
    <el-dialog
      v-model="folderForm.show"
      :title="folderForm.folderID == null ? '添加分类' : '重命名'"
      width="350px"
      center
    >
      <el-input
        v-model="folderForm.folderName"
        ref="folderNameInput"
        autofocus
        @keydown.enter="addFolder"
        placeholder="输入分类名称"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="folderForm.show = false"> 取消 </el-button>
          <el-button type="primary" @click="addFolder"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 重命名游戏 -->
    <el-dialog
      v-model="gameForm.showRename"
      title="重命名"
      width="350px"
      center
    >
      <el-input
        v-if="contextMenu.game.item != null"
        v-model="contextMenu.game.item.gameName"
        ref="folderNameInput"
        autofocus
        @keydown.enter="addFolder"
        placeholder="输入游戏名称"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="gameForm.showRename = false"> 取消 </el-button>
          <el-button type="primary" @click="remarkGame"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加壁纸对话框 -->
    <el-dialog
      v-model="wallpaperForm.show"
      v-if="wallpaperForm.show"
      title="添加壁纸"
      width="60%"
    >
      <el-form label-width="5rem">
        <el-form-item label="壁纸类型">
          <el-radio-group v-model="wallpaperForm.type">
            <!-- img、video、url、combine组合图片 -->
            <el-radio label="imgOrVideo">图片/视频</el-radio>
            <el-radio label="cutVideo">视频剪辑</el-radio>
            <el-radio label="combine">拼图</el-radio>
            <el-radio label="url">网页</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="网页链接" v-if="wallpaperForm.type == 'url'">
          <el-input
            v-for="(item, idx) in wallpaperForm.url"
            :key="idx"
            v-model="item.url"
            placeholder="输入网页链接"
          />
        </el-form-item>
        <el-form-item label="壁纸名称">
          <el-input
            v-model="wallpaperForm.name"
            ref="wallpaperNameInput"
            placeholder="输入壁纸名称"
          />
        </el-form-item>
        <el-form-item label="组合类型" v-if="wallpaperForm.type == 'combine'">
          <el-select
            v-model="wallpaperForm.combineName"
            filterable
            placeholder="选择拼图模板"
          >
            <el-option
              v-for="item in combineList"
              :key="item.name"
              :label="item.cName"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="组合预览"
          v-if="
            wallpaperForm.type == 'combine' && wallpaperForm.combineName != null
          "
        >
          <div :class="'combine selectImgItem ' + wallpaperForm.combineName">
            <div
              class="combineItem row vCenter hCenter"
              v-for="(sub, sidx) in wallpaperForm.url"
              :data-no="sidx"
              :key="sub.url"
              @click="selectImage(sidx, true)"
            >
              <!-- 图片 -->
              <img
                class="w100 h100"
                v-if="sub.url != ''"
                :src="'http://localhost:12008/static/temp/' + sub.url"
                alt=""
              />
              <el-icon v-else>
                <Plus />
              </el-icon>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="壁纸内容"
          v-if="wallpaperForm.type == 'imgOrVideo'"
        >
          <div class="row scrollX imgListPanel">
            <div
              @click="selectImage(idx, true)"
              class="selectImgItem shrink"
              v-for="(img, idx) in wallpaperForm.url"
              :key="idx"
            >
              <img
                class="selectImgView"
                :src="'http://localhost:12008/static/temp/' + img.url"
              />
            </div>
            <div
              @click="selectImage(wallpaperForm.url.length, true)"
              class="selectImgItem shrink"
            >
              <el-icon>
                <Plus />
              </el-icon>
            </div>
          </div>
        </el-form-item>
        <!-- 视频剪辑 -->
        <el-form-item label="视频" v-if="wallpaperForm.type == 'cutVideo'">
          <div
            class="row vCenter hCenter"
            @click="selectCutVideo"
            :class="{ notCutVideoState: cutVideo.url == '' }"
            id="cutVideoPanel"
          >
            <video
              id="cutVideo"
              ref="cutVideo"
              :muted="cutVideo.mute"
              :volume="cutVideo.volume / 100"
              :playbackRate="cutVideo.playbackRate"
              @loadedmetadata="cutVideoLoadedmetadata"
              @ended="cutVideoEnded"
              @click.stop=""
              @play="cutVideoPlayEvent"
              @pause="cutVideo.play = false"
              @timeupdate="cutVideoTimeUpdate"
              v-if="cutVideo.url != ''"
              :src="'http://localhost:12008/static/temp/' + cutVideo.url"
              controls
            ></video>
            <div class="col vCenter hCenter" v-if="cutVideo.url == ''">
              <el-icon id="selectCutVideoIco">
                <Film />
              </el-icon>
              <span>选择视频</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="媒体" v-if="wallpaperForm.type == 'cutVideo'">
          <div class="row vCenter cutOptPanel">
            <div class="row vCenter">
              <el-text class="mx-1" type="info"> 音量： </el-text>
              <el-slider v-model="cutVideo.volume" />
            </div>
            <div class="row vCenter">
              <el-text class="mx-1" type="info">速度：</el-text>
              <el-slider
                :min="0.1"
                :max="8"
                :step="0.1"
                v-model="cutVideo.playbackRate"
              />
            </div>
            <div class="row vCenter" :disabled="Number(localVer) < 2.2">
              <el-checkbox v-model="cutVideo.reverse" label="洗脑倒放" />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="截取" v-if="wallpaperForm.type == 'cutVideo'">
          <div class="row vCenter cutOptPanel">
            <el-input
              v-model="cutVideo.startTime"
              disabled
              placeholder="开始时间"
              style="width: 100px"
            />
            <el-button type="primary" @click="setCutVideoTime(0)"
              >从这开始</el-button
            >
            <el-input
              v-model="cutVideo.endTime"
              disabled
              placeholder="结束时间"
              style="width: 100px"
            />
            <el-button type="danger" @click="setCutVideoTime(1)"
              >到此结束</el-button
            >
            <el-button
              :type="cutVideo.loop ? 'danger' : 'primary'"
              :disabled="cutVideo.startTime == '' || cutVideo.endTime == ''"
              @click="cutVideoPreview"
            >
              {{ cutVideo.loop ? "取消预览" : "预览结果" }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="wallpaperForm.show = false"> 取消 </el-button>
          <el-button type="primary" @click="addWallpaper"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加game对话框 -->
    <el-dialog
      v-model="gameForm.show"
      v-if="gameForm.show"
      title="添加游戏"
      width="60%"
    >
      <div id="addGamePanel" class="w100 h100">
        <div class="addGameItem" v-for="(g, gidx) in gameForm.list" :key="gidx">
          <img
            v-if="g.gameCover == '' || g.gameCover == 'game.jpg'"
            @click="renewGameCover(g)"
            class="addGameItemCover default w100 h100"
            title="点击更换封面"
            src="http://localhost:12008/static/images/game.png"
          />
          <img
            v-else
            @click="renewGameCover(g)"
            class="addGameItemCover w100 h100"
            :src="'http://localhost:12008/static/temp/' + g.gameCover"
          />
          <input
            type="text"
            class="addGameItemName"
            placeholder="输入游戏名称"
            v-model="g.gameName"
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="gameForm.show = false"> 取消 </el-button>
          <el-button type="primary" @click="addGameBtnEvent"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 更新提示对话框 -->
    <el-dialog v-model="showUpdate" title="版本更新" width="500" center>
      <!-- <div>检测到软件版本更新，是否下载最新版更新？</div> -->
      <div v-html="updateRemark"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showUpdate = false">暂不更新</el-button>
          <el-button type="primary" @click="downloadUpdate">
            下载更新
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 公共弹窗 -->
    <el-dialog
      v-model="dialog.show"
      :close-on-click-modal="true"
      :title="dialog.title"
      :width="dialog.width"
      center
    >
      <div v-html="dialog.content"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialog.show = false">
            {{ dialog.btnText }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 设置抽屉 -->
    <el-drawer v-model="showSettings" direction="btt" size="50%">
      <template #header="{}">
        <div class="row vCenter">
          <span>设置</span>
        </div>
      </template>
      <div class="w100 h100 row">
        <el-tabs tab-position="left" v-model="selectdTab" class="full">
          <el-tab-pane label="切换效果" name="切换效果">
            <div id="effectList" class="w100 h100">
              <el-checkbox-group
                @change="effectChangeEvent"
                v-model="playSettings.effects"
                id="checkgroupPanel"
              >
                <el-checkbox
                  v-for="item in effectList"
                  :label="item.value"
                  :key="item.name"
                >
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
          <el-tab-pane label="屏保设置" name="屏保设置">
            <el-form label-width="auto">
              <el-form-item label="是否开启">
                <el-radio-group v-model="playSettings.scrEnable">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="设置时间">
                <el-input-number
                  v-model="playSettings.scrTime"
                  :disabled="playSettings.scrEnable == 0"
                  size="small"
                  min="10"
                  max="10000000"
                  :step="5"
                />
                <span style="margin-left: 1rem; color: #606266">秒</span>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="setSrcEnableEvent"
                >
                  保存设置
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="存储路径" name="存储路径">
            <el-input v-model="softConfig.staticDir">
              <template #prepend>
                <el-button @click="setStaticDir">
                  <el-icon>
                    <Edit />
                  </el-icon>
                </el-button>
              </template>
              <template #append>
                <el-button @click="saveSoftConfig"> 确定 </el-button>
              </template>
            </el-input>
            <p
              class="row vCenter hCenter"
              style="font-size: 0.8rem; color: #ccc"
            >
              注意：修改数据存储路径后需要重启软件，否则可能无法正常显示
            </p>
          </el-tab-pane>
          <el-tab-pane label="开机启动" name="开机启动">
            <el-checkbox @change="setStartup" v-model="autoStart">
              开机启动
            </el-checkbox>
          </el-tab-pane>
          <el-tab-pane label="转码性能" name="转码性能">
            <el-input
              type="number"
              max="8"
              min="1"
              v-model="playSettings.thread"
            >
              <template #prepend>线程数量</template>
              <template #append>
                <el-button @click="saveConfig(true)"> 确定 </el-button>
              </template>
            </el-input>
            <p
              class="row vCenter hCenter"
              style="font-size: 0.8rem; color: #ccc"
            >
              多线程可以加快视频转码速度，但线程数量并不是越多越好。推荐设置为电脑处理器核心数/2，不要超过8。您的电脑CPU核心数是：
              {{ cpuCount }}
            </p>
          </el-tab-pane>
          <el-tab-pane label="使用引导" name="使用引导">
            <el-link type="primary" style="margin-left: 1rem" @click="gotoTour"
              >查看使用引导</el-link
            >
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <!-- 使用引导 -->
    <el-tour
      v-model="showTour"
      placement="bottom"
      @close="showStore"
      :z-index="9999"
    >
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_homeBtn"
        title="主页"
      >
        <div>点击此处返回主页面。</div>
        <div>小提示：点击左侧软件LOGO也是有同样效果。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_storeBtn"
        title="壁纸库"
      >
        <div>点击此处前往“官方壁纸库”下载壁纸。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_downloadBtn"
        title="下载列表"
      >
        <div>点击此处查看“下载任务”列表。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_aboutBtn"
        title="联系开发者"
      >
        <div>点击此处获取此软件开发者联系方式。</div>
        <div>
          您在使用软件的过程中“遇到问题”，或者希望对软件有“改进建议”的可随时联系开发者。
        </div>
        <div>欢迎大家多提供好的建议，让软件更加完善。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_themeBtn"
        title="软件主题"
      >
        <div>点击此处选择“软件背景”主题。</div>
        <div>或点击“自定义”选自您电脑上的图片作为主题。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#top_miniwinBtn"
        title="隐藏窗口"
      >
        <div>点击此处将软件“最小化”至任务栏右下角小图标。</div>
        <div>点击右下角小图标恢复显示软件窗口。</div>
        <div>若需要“退出软件”请右键点击任务栏右下角软件图标选择退出。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#volumePanel"
        title="声音控制"
      >
        <div>点击“铃铛”图标切换“静音”或“非静音”状态，右侧调整音量</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#foot_autoplayBtn"
        title="自动切换"
      >
        <div>点击此处选择“是否自动切换”壁纸。</div>
        <div>
          当您选择自动切换壁纸模式下，按默认10秒或您设置的时间为周期自动切换壁纸。
        </div>
        <div>
          自动切换周期时间只对非视频类壁纸有效，“视频类”为“播放完毕后”自动切换。
        </div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#foot_modeBtn"
        title="自动切换模式"
      >
        <div>点击此处更改壁纸“自动切换模式”。</div>
        <div>单个循环：软件将一直播放您最后选择的壁纸。</div>
        <div>
          列表循环：自动切换壁纸周期到时，软件将自动“循环当前壁纸分类里的壁纸”。
        </div>
        <div>
          随机循环：自动切换壁纸周期到时，软件将自动“随机选择当前壁纸分类里的壁纸”。
        </div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#foot_timeBtn"
        title="自动切换周期时间"
      >
        <div>点击此处设置壁纸的“自动切换周期时间”，单位“秒”。</div>
        <div>
          注意：自动切换周期时间默认对“视频类”无效，视频类在播放结束后触发切换。
        </div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#foot_surplusTime"
        title="自动切换壁纸倒计时"
      >
        <div>此处显示自动切换壁纸的剩余时间，单位“秒”。</div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="tourNextBtn"
        target="#foot_effctBtn"
        title="壁纸切换过度效果"
      >
        <div>
          点击此处选择壁纸切换过程中的“过度效果”，多选时每次随机选择一个效果。
        </div>
        <div>当前部分效果存在BUG，后续将进行修复。</div>
        <div>
          您若有好的“创意”可联系开发者，开发者无偿为您增加您想要的效果。
        </div>
      </el-tour-step>
      <el-tour-step
        :prev-button-props="tourPreBtn"
        :next-button-props="{ children: '开始使用' }"
        target="#middel_addFolderBtn"
        title="壁纸分类"
      >
        <div>点击此处新建“壁纸分类”，新建分类后方可将壁纸添加至其中。</div>
      </el-tour-step>
    </el-tour>
    <!-- 使用引导询问对话框 -->
    <el-dialog v-model="showTourDialog" title="使用引导" width="500" center>
      <div style="padding: 1rem">您是否需要查看“软件使用引导”教程 ？</div>
      <div style="padding: 1rem">
        玩法、壁纸资源分享、使用问题等，可联系微信“
        <span style="color: blue"> nobugltd </span>
        ”加群交流。
      </div>
      <div style="padding: 1rem">
        注意：若您发现背景窗口不在桌面底部，请检查是否安装有360安全卫士等杀毒软件（会阻止壁纸窗口设置到桌面底部），请先退出杀毒软件再启动。软件启动后方可重新运行软件，如有疑问可联系开发者
        <span style="color: blue"> 微信号：nobugltd </span>
        。
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showTourDialog = false">下次一定看</el-button>
          <el-button @click="noDisplayTour" type="warning"
            >我是大神，懂？</el-button
          >
          <el-button type="success" @click="showTourEvent">
            我需要看看
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 导入壁纸对话框 -->
    <el-dialog
      v-model="input.showInputDialog"
      :title="`${
        input.tag == '' || input.tag == null
          ? '导入壁纸'
          : '“' + input.tag + '”分享的壁纸'
      }（共${input.list.length}张）`"
      width="60%"
    >
      <el-form label-width="auto">
        <el-form-item label="导入位置">
          <el-select
            v-model="input.folderID"
            placeholder="选择导入位置"
            style="width: 240px"
          >
            <el-option label="自动同步分类" :value="-1" />
            <el-option
              v-for="f in folders"
              :key="f.folderID"
              :label="f.folderName"
              :value="f.folderID"
            />
          </el-select>
        </el-form-item>

        <el-tabs tab-position="top" v-model="input.selectTab" class="demo-tabs">
          <el-tab-pane :label="f" v-for="f in input.folders" :name="f" :key="f">
            <div class="inputListPanel">
              <div
                v-for="(item, idx) in input.list"
                :key="item.id"
                v-show="item.folderName == f || f == '全部'"
                :class="
                  'wallpaperItem ' +
                  (item.type == 'combine' ? 'combine ' + item.combineName : '')
                "
              >
                <div class="deletePanel row vCenter hCenter w100 h100">
                  <el-icon
                    @click="input.list.splice(idx, 1)"
                    class="deleteOutputItemIcon"
                    size="2rem"
                  >
                    <Delete />
                  </el-icon>
                </div>
                <div
                  :class="
                    'wallpaper row vCenter hCenter ' +
                    (item.type == 'combine' ? 'combineItem ' : '')
                  "
                  v-for="(img, c_idx) in item.url"
                  :data-no="c_idx"
                  :key="c_idx"
                >
                  <el-icon
                    v-if="
                      item.type == 'url' &&
                      (item.cover == '' || item.cover == null)
                    "
                    size="35"
                    color="#fff"
                    style="margin-top: -0.5rem"
                  >
                    <Link />
                  </el-icon>
                  <div
                    class="wallpaperName"
                    v-if="
                      item.type == 'url' &&
                      (item.cover == '' || item.cover == null)
                    "
                    v-html="item.name"
                  ></div>
                  <img
                    v-else
                    class="wallpaperImg"
                    :src="
                      'http://localhost:12008/static/temp/' +
                      (item.type == 'url' ? item.cover : img.url)
                    "
                  />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="input.showInputDialog = false"> 取消 </el-button>
          <el-button type="primary" @click="saveInputFile"> 导入 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 导入game对话框 -->
    <el-dialog
      v-model="inputGame.showInputDialog"
      :title="`${
        inputGame.tag == '' || inputGame.tag == null
          ? '导入游戏'
          : '“' + inputGame.tag + '”分享的游戏'
      }（共${inputGame.list.length}个）`"
      width="60%"
    >
      <div class="inputListPanel">
        <div
          v-for="(item, idx) in inputGame.list"
          :key="item.id"
          class="gameItem"
        >
          <div class="deletePanel row vCenter hCenter w100 h100">
            <el-icon
              @click="inputGame.list.splice(idx, 1)"
              class="deleteOutputItemIcon"
              size="2rem"
            >
              <Delete />
            </el-icon>
          </div>
          <img
            v-if="item.gameCover == 'game.jpg'"
            class="gameCover default w100 h100"
            src="http://localhost:12008/static/images/game.png"
            alt=""
          />
          <img
            v-else
            class="gameCover w100 h100"
            :src="'http://localhost:12008/static/temp/' + item.gameCover"
            alt=""
          />
          <div class="gameName w100" v-html="item.gameName"></div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="inputGame.showInputDialog = false">
            取消
          </el-button>
          <el-button type="primary" @click="saveInputGameFile">
            导入
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
let click = false;
let x, y;

import {
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElLoading as elLoading,
} from "element-plus";
import combineConfig from "@/assets/combineConfig/index.js";
import effectConfig from "@/assets/effctConfig/index.js";
import themeConfig from "@/assets/themeConfig/index.js";
import { h } from "vue";
const downloadDict = {};
//导出loading动画
let outputLoading = null;
//导入loading动画
let inputLoading = null;
//计时
let addTimeTimer = null;
export default {
  name: "appPage",
  data() {
    return {
      //是否正在输入的状态
      compositionState: false,
      //游戏搜索内容
      gameSearchTextInput: "",
      gameSearchText: "",
      gvdm: {
        lastID: 0,
        //id:玩的次数
        data: {},
        //id:排在置顶第几位 数值越大越靠前
        tops: {},
      },
      playTime: 0,
      view: {
        show: false,
        item: {},
      },
      input: {
        //显示密码输入框
        showPwdDialog: false,
        //显示导入对话框
        showInputDialog: false,
        password: "",
        filePath: "",
        //壁纸列表
        list: [],
        //包里有的目录
        folders: [],
        //导入到哪个目录，-1表示自动创建目录
        folderID: -1,
        selectTab: "全部",
      },
      inputGame: {
        //显示密码输入框
        showPwdDialog: false,
        //显示导入对话框
        showInputDialog: false,
        password: "",
        filePath: "",
        //game列表
        list: [],
      },
      output: {
        defaultName: "壁纸",
        //是否显示导出对话框
        showOutputDialog: false,
        //要导出的壁纸
        outputWallpaper: [],
        password: "",
        savePath: "",
        tag: "",
      },
      outputGame: {
        defaultName: "游戏",
        //是否显示导出对话框
        showOutputDialog: false,
        //要导出的壁纸
        outputGame: [],
        password: "",
        savePath: "",
        tag: "",
      },
      //gameList
      gameList: [],
      //选择文件夹模式
      selectFolderMode: false,
      selectFolderDict: {},
      //选择壁纸模式
      selectWallpaperMode: false,
      selectWallpaperDict: {},
      //选择game模式
      selectGameMode: false,
      selectGameDict: {},
      //本地软件版本
      localVer: "",
      //线上版本
      onlineVer: "",
      //视频剪辑参数
      cutVideo: {
        url: "",
        startTime: "",
        endTime: "",
        mute: false,
        play: false,
        st: 0,
        et: 100,
        loop: false,
        param: "",
        volume: 0,
        playbackRate: 1.0,
        reverse: false,
      },
      //是否显示使用引导
      showTour: false,
      showTourDialog: false,
      tourPreBtn: {
        children: "上一步",
      },
      tourNextBtn: {
        children: "下一步",
      },
      downloadData: {
        //选择显示类型
        selectType: 0,
        show: false,
        //下载列表
        list: [],
      },
      //右键菜单数据
      contextMenu: {
        folder: {
          idx: -1,
          item: null,
        },
        wallpaper: {
          idx: -1,
          item: null,
        },
        game: {
          idx: -1,
          item: null,
        },
      },
      //壁纸市场
      store: {
        //预览链接
        previewImg: ``,
        //是否预览图片
        preview: false,
        selectWallpaper: null,
        typeList: [],
        list: [],
        page: 0,
        //-2为最新，-1为热门
        selectTypeID: -2,
        pageEnd: false,
        loadData: false,
        imgServer: "//image.nobugltd.com/wallpaper/",
      },
      //公共弹窗的数据
      dialog: {
        show: false,
        title: "",
        width: "",
        content: "",
        btnText: "",
      },
      //是否显示更新
      showUpdate: false,
      //更新内容
      updateRemark: ``,
      //皮肤列表
      themeList: themeConfig,
      selectdTab: "切换效果",
      //app界面是否已加载完成
      appReady: false,
      // 软件配置
      softConfig: {
        staticDir: "",
        bgDir: "",
        miniImageDir: "",
        dbPath: "",
      },
      showSettings: false,
      //特效列表
      effectList: effectConfig,
      playSettings: {
        play: -1,
        time: -1,
        volume: -1,
        //listLoop 列表循环,oneLoop 单个循环,random 随机
        loopMode: "listLoop",
        //是否静音
        mute: -1,
        //上次选择文件夹lastSelectFolderID
        lastSelectFolderID: null,
        //上次选择壁纸
        lastPlayWallpaperID: null,
        //主题
        theme: "theme.png",
        //选择启用的特效
        effects: [],
        //是否开机自动启动
        startup: 1,
        //转码线程数量
        thread: 4,
        cpuCount: 1,
        //时间是否对视频有效
        timeApplyVideo: false,
        //是否启用屏保
        scrEnable: 0,
        //多少秒进入屏保
        scrTime: 60,
      },
      //拼图列表
      combineList: combineConfig,
      //文件夹表单
      folderForm: {
        //是否显示添加文件夹对话框
        show: false,
        //文件夹名称
        folderName: "",
        folderID: null,
        PID: 0,
        children: [],
      },
      //壁纸表单
      wallpaperForm: {
        //是否显示添加壁纸对话框
        show: false,
        id: null,
        folderID: null,
        //类型 img、video、url、combine组合图片
        type: "imgOrVideo",
        combineName: null,
        combineImgCount: null,
        url: [],
        data: null,
        name: null,
      },
      gameForm: {
        showRename: false,
        show: false,
        //{ID,gameCover,gameFile,gameName}
        list: [],
      },
      //folder 、 wallpaper
      showType: "folder",
      //显示页面 myWallpaper我的壁纸页面  storeWallpaper网络壁纸页  myGame我的游戏
      showPage: localStorage.getItem("lastPage") || "storeWallpaper", //storeWallpaper
      //文件夹列表
      folders: [
        {
          // folderName: "收藏",
        },
      ],
      //壁纸列表
      wallpapers: [],
      //当前在播放的文件夹壁纸列表
      playWallpapers: [],
      //当前播放的第几张壁纸
      playWallpaperIdx: -1,
      //添加壁纸加载框
      addLoading: null,
      //选择文件加载框
      selectFileLoading: null,
      //选择game加载框
      selectGameLoading: null,
      //添加game加载框
      addGameLoading: null,
      //当前选择文件夹ID
      selectFolderID: null,
    };
  },
  methods: {
    //修改游戏封面
    async renewGameNewCover() {
      if (this.localVer < 2.7) {
        this.$toast.show("当前版本不支持该功能，请升级到2.7以上版本");
        return;
      }
      const fres = await this.$ws.send("file.selectImage", { multi: 1 });
      if (fres.status != 200) {
        this.$toast.show("已取消");
        return;
      }
      const file = fres.data[0];
      const game = {};
      Object.assign(game, this.contextMenu.game.item);
      game.gameCover = file.url;

      await this.$ws.send("game.renew", {
        g: game,
      });
      this.contextMenu.game.item.gameCover = game.gameCover;

      this.$toast.show("修改成功");
    },
    //设置游戏置顶
    async setTopGame() {
      let game = this.contextMenu.game.item;
      if (this.gvdm.tops[game.ID] != null) {
        this.$toast.show("该游戏已经处于置顶状态");
        return;
      }
      let count = Object.keys(this.gvdm.tops).length;
      this.gvdm.tops[game.ID] = count;
      this.saveGvdm();
      this.$toast.show("置顶成功");
    },
    //取消游戏置顶
    async cancelTopGame() {
      let game = this.contextMenu.game.item;
      delete this.gvdm.tops[game.ID];
      this.saveGvdm();
      this.$toast.show("已取消");
    },
    //保存统计数据
    async saveGvdm() {
      let gvdm = {
        lastID: this.gvdm.lastID,
        datas: [],
        tops: [],
      };
      for (const key in this.gvdm.data) {
        gvdm.datas.push({
          ID: key,
          count: this.gvdm.data[key],
        });
      }
      for (let key in this.gvdm.tops) {
        gvdm.tops.push(key);
      }
      await this.$ws.send("game.saveViewData", {
        gvdm: gvdm,
      });
    },
    //输入搜索游戏内容
    inputGameSearchTextEvent() {
      if (this.compositionState) {
        return;
      }
      this.gameSearchText = this.gameSearchTextInput;
      console.log("搜索了");
    },
    //重命名游戏
    async remarkGame() {
      await this.$ws.send("game.renew", {
        g: this.contextMenu.game.item,
      });
      this.gameForm.showRename = false;
      this.$toast.show("修改成功");
    },
    //获取gameList
    async getGameList() {
      const data = await this.$ws.send("game.getAll", {});
      const gvdm = await this.$ws.send("game.getViewData", {});
      if (gvdm != null) {
        this.gvdm.lastID = gvdm.lastID || 0;
        if (gvdm.datas instanceof Array) {
          for (const item of gvdm.datas) {
            this.gvdm.data[item.ID] = item.count;
          }
        }
        if (gvdm.tops instanceof Array) {
          for (let i = 0; i < gvdm.tops.length; i++) {
            let id = gvdm.tops[i];
            this.gvdm.tops[id] = i;
          }
        }
      }
      this.gameList = data;
      console.log(data, gvdm);
    },
    //添加game
    async addGameBtnEvent() {
      if (this.gameForm.list.length <= 0) {
        this.$toast.show("请选择游戏");
        return;
      }
      this.gameForm.show = false;
      this.addGameLoading = elLoading.service({
        text: `正在添加游戏`,
        target: "#content",
      });
      for (const g of this.gameForm.list) {
        await this.$ws.send("game.add", {
          g: g,
        });
      }
      this.addGameLoading.close();
      this.getGameList();
      this.$toast.show("添加成功");
    },
    //修改game封面
    async renewGameCover(g) {
      const res = await this.$localHttp.post("/file/selectImage", {
        multi: false,
        title: "选择封面",
      });
      if (res.status != 200) {
        return;
      }
      let file = res.data[0];
      console.log(file);
      g.gameCover = file.url;
    },
    //选择game
    async selectGame() {
      if (Number(this.localVer) < 2.6) {
        this.$toast.show("当前版本不支持，请升级至最新版！");
        return;
      }
      this.selectGameLoading = elLoading.service({
        text: `正在选择文件`,
        target: "#content",
      });
      const res = await this.$localHttp.post("/file/selectNes", {});
      this.selectGameLoading.close();
      if (res.status != 200) {
        return;
      }
      this.gameForm.list = [];
      for (const item of res.data) {
        this.gameForm.list.push({
          // ID: item.ID,
          gameCover: "game.jpg",
          gameFile: item.source,
          // eslint-disable-next-line
          gameName: `${item.source}`.replace(
            // eslint-disable-next-line
            /[\s\S]+?[\/\\]([^\/\\]+?)\.nes$/gi,
            "$1"
          ),
        });
      }
      this.gameForm.show = true;
      console.log(this.gameForm.list);
    },
    //设置屏保
    setSrcEnableEvent() {
      this.saveConfig(true);
      this.showSettings = false;
    },
    //显示添加壁纸对话框
    showAddWallpaperForm() {
      this.wallpaperForm.show = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.wallpaperNameInput.focus();
        }, 200);
      });
    },
    //显示添加文件夹对话框
    showAddFolderForm() {
      this.folderForm.show = true;
      this.folderForm.folderID = null;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.folderNameInput.focus();
        }, 200);
      });
    },
    //显示第三方源提示
    showWarning(item) {
      let url = "";
      if (typeof item.url != "string") {
        url = item.url[0].url;
      } else {
        url = item.url;
      }
      console.log(item);
      let uri = new URL(url);

      ElMessageBox({
        title: "免责声明",
        message: h("div", {}, [
          h(
            "p",
            { style: `color:#333;font-weight:bold;` },
            `内容来源：${uri.hostname}`
          ),
          h(
            "p",
            { style: `color:#333;` },
            `此壁纸网页内容连接到第三方网站，我们后期对其内容、广告或任何其他信息无法进行任何形式的审核、编辑或控制。因此，我们对这些网页内容不承担任何法律责任。`
          ),
          h(
            "p",
            { style: `color:#333;` },
            "用户在使用本软件时，应自行承担访问这些网页内容的风险。我们建议用户在访问任何第三方网页时，保持警惕并遵守相关法律法规。"
          ),
          h(
            "p",
            { style: `color:#333;` },
            "如有关于本软件的任何问题或疑虑，请随时与我们联系。我们将尽力提供帮助，但不对因使用本软件而产生的任何损害承担责任。"
          ),
          h(
            "p",
            { style: `color:#333;` },
            `该内容来源于网络收集和用户建议，若侵犯了您的合法权益请联系微信“nobugltd”，我们核实后将第一时间删除，谢谢。`
          ),
          h(
            "p",
            { style: `color:#333;font-weight:bold;` },
            `若您在使用过程中发现其无法正常显示或出现不良信息时，请您联系此软件开发者对其从平台中删除引用，非常感谢。`
          ),
        ]),
        confirmButtonText: "已知悉",
        showClose: false,
      });
    },
    gotoTour() {
      for (let i = 0; i < 5; i++) {
        this.gotoHome();
      }
      this.selectFolderMode = false;
      this.selectWallpaperMode = false;
      this.showSettings = false;
      this.showTour = true;
      this.showPage = "myWallpaper";
    },
    //保存导入文件
    async saveInputFile() {
      this.input.showInputDialog = false;
      inputLoading = ElLoading.service({
        text: "正在导入壁纸",
        target: "#content",
      });
      const res = await this.$ws.send("input.saveWallpaper", {
        list: this.input.list,
        folderID: this.input.folderID,
      });
      inputLoading.close();
      if (res.status != 200) {
        this.$toast.show(res.message);
        this.input.showInputDialog = true;
        return;
      }
      this.$toast.show("导入成功");
      if (this.input.folderID == this.selectFolderID) {
        this.getWallpaperByFolderID({
          folderID: this.input.folderID,
        });
      }
      this.getFolderList();
      console.log(res);
    },
    //保存导入game文件
    async saveInputGameFile() {
      this.inputGame.showInputDialog = false;
      inputLoading = ElLoading.service({
        text: "正在导入游戏",
        target: "#content",
      });
      const res = await this.$ws.send("input.saveGame", {
        list: this.inputGame.list,
      });
      inputLoading.close();
      if (res.status != 200) {
        this.$toast.show(res.message);
        this.inputGame.showInputDialog = true;
        return;
      }
      await this.getGameList();
      this.$toast.show("导入成功");
    },
    //导入壁纸文件
    async inputFile() {
      this.input.showPwdDialog = false;
      this.input.showInputDialog = false;
      inputLoading = ElLoading.service({
        text: "正在解压壁纸",
        target: "#content",
      });
      const res = await this.$ws.send("input.wallpaper", {
        filePath: this.input.filePath,
        password: this.input.password,
      });
      inputLoading.close();
      if (res.status != 200) {
        this.input.showPwdDialog = true;
        this.$toast.show(res.message);
        return;
      }
      this.input.showPwdDialog = false;
      this.input.showInputDialog = true;
      this.input.list = res.data.list;
      this.input.folderID = -1;
      this.input.selectTab = "全部";
      this.input.tag = res.data.tag;
      //整理目录
      var folders = ["全部"];
      for (const w of res.data.list) {
        if (!folders.includes(w.folderName)) {
          folders.push(w.folderName);
        }
      }
      this.input.folders = folders;
    },
    //导入game文件
    async inputGameFile() {
      this.inputGame.showPwdDialog = false;
      this.inputGame.showInputDialog = false;
      inputLoading = ElLoading.service({
        text: "正在解压游戏包",
        target: "#content",
      });
      const res = await this.$ws.send("input.game", {
        filePath: this.inputGame.filePath,
        password: this.inputGame.password,
      });
      inputLoading.close();
      if (res.status != 200) {
        this.inputGame.showPwdDialog = true;
        this.$toast.show(res.message);
        return;
      }
      this.inputGame.showPwdDialog = false;
      this.inputGame.showInputDialog = true;
      this.inputGame.list = res.data.list;
      this.input.tag = res.data.tag;
    },
    //关闭导入文件
    hideInput() {
      this.input.showPwdDialog = false;
      inputLoading.close();
    },
    hideInputGame() {
      this.inputGame.showPwdDialog = false;
      inputLoading.close();
    },
    //导出壁纸
    async startOutput() {
      const arr = [];
      for (const w of this.output.outputWallpaper) {
        arr.push(w.id);
      }
      outputLoading = elLoading.service({
        text: "正在导出壁纸",
        target: "#content",
      });
      this.output.showOutputDialog = false;
      let pwd = "nobugltd";
      if (this.output.password != "") {
        pwd = this.output.password;
      }
      if (this.output.savePath == "") {
        outputLoading.close();
        this.$toast.show("请选择保存路径");
        this.output.showOutputDialog = true;
        return;
      }
      const res = await this.$ws.send("output.wallpaper", {
        ids: arr.join(`,`),
        savePath: this.output.savePath,
        password: pwd,
        tag: this.output.tag,
      });
      outputLoading.close();
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
        });
        return;
      }
      this.selectFolderMode = false;
      this.selectWallpaperMode = false;
      this.selectFolderDict = {};
      this.selectWallpaperDict = {};
      this.output.savePath = "";
      this.$toast.show("导出成功");
      this.inoutSelectFolderMode(false);
      this.inoutSelectWallpaperMode(false);
    },
    //导出游戏
    async startOutputGame() {
      const arr = [];
      for (const g of this.outputGame.outputGame) {
        arr.push(g.ID);
      }
      outputLoading = elLoading.service({
        text: "正在导出游戏",
        target: "#content",
      });
      this.outputGame.showOutputDialog = false;
      let pwd = "nobugltd";
      if (this.outputGame.password != "") {
        pwd = this.outputGame.password;
      }
      if (this.outputGame.savePath == "") {
        outputLoading.close();
        this.$toast.show("请选择保存路径");
        this.outputGame.showOutputDialog = true;
        return;
      }
      const res = await this.$ws.send("output.game", {
        ids: arr.join(`,`),
        savePath: this.outputGame.savePath,
        password: pwd,
        tag: this.outputGame.tag,
      });
      outputLoading.close();
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
        });
        return;
      }
      this.selectGameMode = false;
      this.selectGameDict = {};
      this.outputGame.savePath = "";
      this.$toast.show("导出成功");
      this.inoutSelectGameMode(false);
    },
    //导出已选文件夹
    async outputSelectFolder() {
      const arr = [];
      for (const id in this.selectFolderDict) {
        if (this.selectFolderDict[id] == true) {
          arr.push(id);
        }
      }
      if (arr.length == 0) {
        this.$toast.show("请选择要导出的分类");
        return;
      }
      outputLoading = elLoading.service({
        text: "正在获取壁纸信息",
        target: "#content",
      });
      this.output.outputWallpaper = [];
      for (const fid of arr) {
        const res = await this.$localHttp.post("/wallpaper/getByFolderID", {
          folderID: fid,
        });
        this.output.outputWallpaper.push(...res.data);
      }
      outputLoading.close();
      let d = new Date();
      this.output.defaultName = `${d.getFullYear()}_${
        d.getMonth() + 1
      }_${d.getDate()}`;
      this.output.showOutputDialog = true;
    },
    //导出已选game
    async outputSelectGame() {
      const arr = [];
      for (const id in this.selectGameDict) {
        if (this.selectGameDict[id] != null) {
          arr.push(id);
        }
      }
      if (arr.length == 0) {
        this.$toast.show("请选择要导出的游戏");
        return;
      }
      this.outputGame.outputGame = [];
      for (const id in this.selectGameDict) {
        const g = this.selectGameDict[id];
        if (g != null) {
          this.outputGame.outputGame.push(g);
        }
      }
      let d = new Date();
      this.outputGame.defaultName = `${d.getFullYear()}_${
        d.getMonth() + 1
      }_${d.getDate()}`;
      this.outputGame.showOutputDialog = true;
    },
    //导出文件夹
    async outputDirectory() {
      outputLoading = elLoading.service({
        text: "正在获取壁纸信息",
        target: "#content",
      });
      this.output.outputWallpaper = [];
      const res = await this.$localHttp.post("/wallpaper/getByFolderID", {
        folderID: this.contextMenu.folder.item.folderID,
      });
      this.output.defaultName = this.contextMenu.folder.item.folderName;
      console.log(`this.output.defaultName `, this.output.defaultName);
      this.output.outputWallpaper.push(...res.data);

      outputLoading.close();
      this.output.showOutputDialog = true;
    },
    //导出单张壁纸
    async outputOneWallpaper(showDialog = false) {
      const wallpaper = this.contextMenu.wallpaper.item;
      console.log(wallpaper);
      this.output.outputWallpaper = [];
      this.output.outputWallpaper.push(wallpaper);
      if (showDialog) {
        this.output.showOutputDialog = true;
        return;
      }
      const res = await this.$localHttp.post("/file/selectSavePath", {
        filter: `壁纸文件(*.wallpaper)|*.wallpaper`,
        defaultName: wallpaper.wallpaperName,
      });
      if (res.status != 200) {
        return;
      }
      this.output.password = "";
      this.output.savePath = res.data;
      this.startOutput();
    },
    //导出已选壁纸
    async outputSelectWallpaper() {
      const arr = [];
      for (const id in this.selectWallpaperDict) {
        if (this.selectWallpaperDict[id] != null) {
          arr.push(id);
        }
      }
      if (arr.length == 0) {
        this.$toast.show("请选择要导出的壁纸");
        return;
      }
      this.output.outputWallpaper = [];
      for (const id in this.selectWallpaperDict) {
        const wp = this.selectWallpaperDict[id];
        if (wp != null) {
          this.output.outputWallpaper.push(wp);
        }
      }
      let d = new Date();
      this.output.defaultName = `${d.getFullYear()}_${
        d.getMonth() + 1
      }_${d.getDate()}`;
      this.output.showOutputDialog = true;
    },
    //删除已选文件夹
    async removeSelectFolder() {
      const arr = [];
      for (const id in this.selectFolderDict) {
        if (this.selectFolderDict[id] == true) {
          arr.push(id);
        }
      }

      if (arr.length == 0) {
        this.$toast.show("请选择要删除的分类");
        return;
      }

      for (const id of arr) {
        await this.$localHttp.post("/folder/delete", {
          folderID: id,
        });
      }
      for (let i = 0; i < this.folders.length; i++) {
        if (
          arr.includes(this.folders[i].folderID) ||
          arr.includes(`${this.folders[i].folderID}`)
        ) {
          this.folders.splice(i, 1);
          i--;
        }
      }
      this.$toast.show("删除成功");
      this.inoutSelectFolderMode(false);
    },
    //删除已选游戏
    async removeSelectGame() {
      const arr = [];
      for (const id in this.selectGameDict) {
        if (this.selectGameDict[id] != null) {
          arr.push(id);
        }
      }

      if (arr.length == 0) {
        this.$toast.show("请选择要删除的游戏");
        return;
      }

      for (const id of arr) {
        await this.$ws.send("game.remove", {
          id: id,
        });
      }
      for (let i = 0; i < this.gameList.length; i++) {
        if (
          arr.includes(this.gameList[i].ID) ||
          arr.includes(`${this.gameList[i].ID}`)
        ) {
          this.gameList.splice(i, 1);
          i--;
        }
      }
      this.$toast.show("删除成功");
      this.selectGameDict = {};
      this.inoutSelectGameMode(false);
    },
    //删除选中的壁纸
    async removeSelectWallpaper() {
      const arr = [];
      for (const id in this.selectWallpaperDict) {
        if (this.selectWallpaperDict[id] != null) {
          arr.push(id);
        }
      }

      if (arr.length == 0) {
        this.$toast.show("请选择要删除的壁纸");
        return;
      }

      for (const id of arr) {
        await this.$localHttp.post("/wallpaper/delete", {
          id: id,
        });
      }
      for (let i = 0; i < this.wallpapers.length; i++) {
        const w = this.wallpapers[i];
        if (arr.includes(w.id) || arr.includes(`${w.id}`)) {
          this.wallpapers.splice(i, 1);
          i--;
        }
      }
      this.$toast.show("删除成功");
      this.inoutSelectWallpaperMode(false);
    },
    //选择导出路径
    async selectOutputPath() {
      const loading = ElLoading.service({
        text: "选择保存路径",
        target: "#content",
      });
      this.output.showOutputDialog = false;
      const res = await this.$localHttp.post("/file/selectSavePath", {
        filter: `壁纸文件(*.wallpaper)|*.wallpaper`,
        defaultName: this.output.defaultName || "壁纸",
      });
      loading.close();
      this.output.showOutputDialog = true;
      if (res.status != 200) {
        return;
      }
      this.output.savePath = res.data;
    },
    //选择游戏导出路径
    async selectGameOutputPath() {
      const loading = ElLoading.service({
        text: "选择保存路径",
        target: "#content",
      });
      this.outputGame.showOutputDialog = false;
      const res = await this.$localHttp.post("/file/selectSavePath", {
        filter: `游戏包(*.games)|*.games`,
        defaultName: this.outputGame.defaultName || "游戏包",
      });
      loading.close();
      this.outputGame.showOutputDialog = true;
      if (res.status != 200) {
        return;
      }
      this.outputGame.savePath = res.data;
    },
    //选择文件夹
    inoutSelectFolderMode(io) {
      this.selectFolderMode = io;
      if (io) {
        const folder = this.contextMenu.folder.item;
        this.selectFolderDict[folder.folderID] = true;
        this.$toast.show(
          "开始选择您要操作的分类，右键菜单中可退出选择模式",
          5000
        );
      } else {
        this.selectFolderDict = {};
      }
    },
    //选择游戏
    inoutSelectGameMode(yesOrNo) {
      this.selectGameMode = yesOrNo;
      if (yesOrNo) {
        const game = this.contextMenu.game.item;
        this.selectGameDict[game.ID] = game;
        this.$toast.show(
          "开始选择您要操作的游戏，右键菜单中可退出选择模式",
          5000
        );
      } else {
        this.selectGameDict = {};
      }
    },
    inoutSelectWallpaperMode(io) {
      this.selectWallpaperMode = io;
      if (io) {
        this.$toast.show(
          "开始选择您要操作的壁纸，右键菜单中可退出选择模式",
          5000
        );
        const wallpaper = this.contextMenu.wallpaper.item;
        this.selectWallpaperDict[wallpaper.id] = wallpaper;
      } else {
        this.selectWallpaperDict = {};
      }
    },
    //剪辑视频播放结束后
    cutVideoEnded() {
      if (this.cutVideo.loop) {
        this.$refs.cutVideo.play();
      }
    },
    //剪辑视频加载后
    cutVideoLoadedmetadata() {
      this.cutVideo.et = this.$refs.cutVideo.duration;
    },
    //剪辑视频预览
    cutVideoPreview() {
      this.cutVideo.loop = !this.cutVideo.loop;
      if (this.cutVideo.loop && this.$refs.cutVideo != null) {
        this.$refs.cutVideo.play();
      } else {
        this.$refs.cutVideo.pause();
      }
    },
    //视频播放时间变化时
    cutVideoTimeUpdate() {
      if (this.$refs.cutVideo == null || !this.cutVideo.loop) {
        return;
      }

      if (this.$refs.cutVideo.currentTime >= this.cutVideo.et) {
        this.$refs.cutVideo.currentTime = this.cutVideo.st;
      }
    },
    //剪辑视频播放器播放时
    cutVideoPlayEvent() {
      this.cutVideo.play = true;
      if (this.$refs.cutVideo == null || !this.cutVideo.loop) {
        return;
      }
      this.$refs.cutVideo.currentTime = this.cutVideo.st;
    },
    //秒数转时间
    secToTime(val) {
      val = Number(val);
      const hours = Math.floor(val / 3600);
      const minutes = Math.floor((val % 3600) / 60);
      const remainingSeconds = Number((val % 60).toFixed(3));
      const pad = (num) => (num < 10 ? "0" : "") + num;
      const str = pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds);
      return str;
    },
    //设置截取时间
    setCutVideoTime(type) {
      if (this.$refs.cutVideo == null) {
        this.$toast.show("请选择视频");
        return;
      }

      const val = Number(this.$refs.cutVideo.currentTime);
      const str = this.secToTime(this.$refs.cutVideo.currentTime);
      if (type == 0) {
        this.cutVideo.startTime = str;
        this.cutVideo.st = val;
        this.$refs.cutVideo.play();
      } else {
        if (val < this.cutVideo.st) {
          this.$toast.show("开始时间不能大于结束时间");
          return;
        }
        this.cutVideo.et = val;
        this.cutVideo.endTime = str;
        this.$refs.cutVideo.pause();
      }
    },
    //选择转码后的视频
    async selectCutVideo() {
      const res = await this.selectVideoByTransform();
      if (res.status != 200) {
        return;
      }
      this.cutVideo.url = res.data;
      this.cutVideo.startTime = "";
      this.cutVideo.endTime = "";
    },
    //选择视频并转码 返回 http结果 包含有文件名
    async selectVideoByTransform() {
      this.wallpaperForm.show = false;
      this.addLoading = elLoading.service({
        text: "正在加载视频（需要转码）",
        target: "#content",
      });
      const res = await this.$localHttp.post(
        "/file/selectVideoByTransform",
        {}
      );
      this.addLoading.close();
      this.wallpaperForm.show = true;
      return res;
    },
    //图库设为壁纸
    async setWallpaper() {
      const wallpaper = this.store.selectWallpaper;

      let folderID = -1;
      // const lid = this.selectFolderID;
      // if (lid != "" && lid != null) {
      //   folderID = lid;
      // }
      await this.storeSaveToFolder({
        folderID: folderID,
      });

      const arr = [...this.playWallpapers];
      arr.push({
        id: new Date().getTime(),
        folderID: -1,
        type: wallpaper.type,
        combineName: "",
        combineImgCount: 0,
        url: [
          {
            url: wallpaper.type == "url" ? wallpaper.url : wallpaper.cover,
            source: wallpaper.source,
            type: wallpaper.type,
            druation: "0",
          },
        ],
        data: "",
        name: wallpaper.wallpaperName,
        cover: wallpaper.cover,
      });
      this.playWallpapers = arr;
      setTimeout(() => {
        this.setPlayWallpaperIdx(this.playWallpapers.length - 1);
        this.$toast.show("设置成功");
      }, 500);
    },
    //不在显示使用引导
    noDisplayTour() {
      localStorage.setItem("showTour", "1");
      this.showTourDialog = false;
    },
    //显示使用引导
    showTourEvent() {
      this.gotoHome();
      localStorage.setItem("showTour", "1");
      this.showTour = true;
      this.showTourDialog = false;
    },
    //预览壁纸
    previewWallpaper(wallpaper) {
      this.store.previewImg = `${this.store.imgServer}${wallpaper.source}`;
      this.store.preview = true;
    },
    //下一张或者上一张
    nextView(val) {
      const n = this.view.item;
      let idx = 0;
      for (let i = 0; i < this.wallpapers.length; i++) {
        const w = this.wallpapers[i];
        if (w.id == n.id && w.cover == n.cover && w.name == n.name) {
          idx = i;
          break;
        }
      }
      idx = idx + val;
      if (idx >= this.wallpapers.length) {
        idx = 0;
      }
      if (idx < 0) {
        idx = this.wallpapers.length - 1;
      }

      this.view.item = this.wallpapers[idx] || this.view.item;
    },
    //预览壁纸
    viewWallpaper() {
      this.view.item = this.contextMenu.wallpaper.item;
      this.view.show = true;
      this.$nextTick(() => {
        const v = document.querySelector("#viewPanel");
        const c = document.querySelector("#content");
        var rect = c.getBoundingClientRect();
        v.style.width = `${rect.width}px`;
        v.style.height = `${rect.height}px`;
        v.style.left = `${rect.left}px`;
        //v.style.top = `${rect.top}px`;
      });
    },
    //下载壁纸文件
    downloadWallpaper() {
      const wallpaper = this.store.selectWallpaper;
      this.$serws.send("wallpaper.download", { ID: wallpaper.ID });
      this.$ws.send("file.download", {
        source: `http:${this.store.imgServer}${wallpaper.source}`,
        name: wallpaper.wallpaperName,
      });
      this.$toast.show("已加入下载队列");
    },
    //取消下载
    cancelDownload(task) {
      if (task.cover != null) {
        this.$ws.send("file.downloadCancel", { id: task.cover.id });
      }
      if (task.source != null) {
        this.$ws.send("file.downloadCancel", { id: task.source.id });
      }
      this.$toast.show(`正在取消下载`, 3000);
    },
    //壁纸保存到文件夹
    async storeSaveToFolder(folder) {
      const wallpaper = this.store.selectWallpaper;
      this.$serws.send("wallpaper.download", { ID: wallpaper.ID });
      const res = this.$ws.send("wallpaper.addFormNetwork", {
        cover: `http:${this.store.imgServer}${wallpaper.cover}`,
        //网页传url
        source:
          wallpaper.type == "url"
            ? wallpaper.url
            : `http:${this.store.imgServer}${wallpaper.source}`,
        name: wallpaper.wallpaperName,
        folderID: folder.folderID,
        type: wallpaper.type,
      });

      this.$toast.show("已加入下载队列");
      await res;
      if (folder.folderID == this.selectFolderID) {
        this.getWallpaperByFolderID(folder);
      }
    },
    //显示壁纸商店菜单
    showStoreMenuEvent(wallpaper) {
      console.log(wallpaper);
      this.store.selectWallpaper = wallpaper;
    },
    //显示我的游戏页
    gotoMyGame() {
      this.showPage = "myGame";
      if (this.downloadData.show) {
        this.downloadData.show = false;
        return;
      }
    },
    //返回主页
    gotoHome() {
      //第一优先级下载列表，因为只会在最上面
      if (this.downloadData.show) {
        this.downloadData.show = false;
        return;
      }
      //如果是壁纸库返回主页，因为其他窗口也都在主页
      if (this.showPage == "storeWallpaper" || this.showPage == "myGame") {
        this.showPage = "myWallpaper";
        return;
      } else {
        //有预览的先关闭预览
        if (this.view.show) {
          this.view.show = false;
          return;
        }
        //显示文件夹列表
        this.showType = "folder";
        this.getFolderList();
      }
    },
    //显示壁纸市场
    async showStore() {
      this.downloadData.show = false;
      this.showPage = "storeWallpaper";
      const showStoreTip = localStorage.getItem("showStoreTip");
      if (showStoreTip == null || showStoreTip == "") {
        this.$toast.show(
          "喜欢的壁纸可以“右键点击”设为壁纸或保存到您壁纸的分类哦（推荐）",
          5000
        );
        localStorage.setItem("showStoreTip", "1");
      }
      await this.getStoreTypeList();
    },
    //获取壁纸类型列表
    async getStoreTypeList() {
      const res = await this.$http.get("/wallpaperType/all.php", {});
      this.store.typeList = [
        {
          ID: -2,
          typeName: "最新",
        },
        {
          ID: -1,
          typeName: "热门",
        },
      ];
      this.store.typeList.push(...res.data);
    },
    //获取壁纸
    async getStoreWallpaperList() {
      if (this.store.pageEnd == true) {
        return;
      }
      this.store.loadData = true;
      const res = await this.$http.get(
        `/wallpaper/getList.php?typeID=${this.store.selectTypeID}&page=${this.store.page}`,
        {}
      );
      if (res.data.length == 0) {
        this.store.pageEnd = true;
        this.$toast.show("壁纸已加载完");
      }
      this.store.list.push(...res.data);
      this.$nextTick(() => {
        this.store.loadData = false;
      });
    },
    //选择类型
    selectStoreType(type) {
      if (type.ID == this.store.selectTypeID) {
        return;
      }
      this.store.selectTypeID = type.ID;
      this.store.list = [];
      this.store.page = 1;
      this.store.pageEnd = false;
      this.getStoreWallpaperList();
    },
    //下一页
    storeNext() {
      if (this.store.pageEnd == true || this.store.loadData == true) {
        return;
      }
      this.store.loadData = true;
      this.store.page++;
      this.getStoreWallpaperList();
    },
    //重命名文件夹
    remarkFolder() {
      const folder = this.contextMenu.folder.item;
      console.log(folder);
      this.folderForm.folderName = folder.folderName;
      this.folderForm.show = true;
      this.folderForm.folderID = folder.folderID;
      this.folderForm.folder = folder;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.folderNameInput.focus();
        }, 200);
      });
    },
    //打开壁纸路径
    async openFilePath() {
      const wallpaper = this.contextMenu.wallpaper.item;
      await this.$localHttp.post("/wallpaper/openFilePath", {
        fileName: wallpaper.url[0].source,
      });
    },

    //打开游戏路径
    async openGameFilePath() {
      const game = this.contextMenu.game.item;
      await this.$ws.send("game.openFilePath", {
        fileName: game.gameFile,
      });
    },
    //打开文件路径
    async openOtherPath(filePath) {
      await this.$localHttp.post("/file/openFilePath", {
        filePath: filePath,
      });
    },
    //设置开机启动
    setStartup(val) {
      this.playSettings.startup = val ? 1 : 0;
      this.saveConfig();
    },
    //等待app准备好
    waitAppReady() {
      return new Promise((resolve) => {
        let waitTask = setInterval(() => {
          if (this.appReady) {
            clearInterval(waitTask);
            resolve(true);
          }
        }, 200);
      });
    },
    //选择壁纸
    selectWallpaper(item, idx) {
      if (this.selectWallpaperMode) {
        if (this.selectWallpaperDict[item.id] != null) {
          this.selectWallpaperDict[item.id] = null;
        } else {
          this.selectWallpaperDict[item.id] = item;
        }
        return;
      }
      this.playWallpapers = this.wallpapers;
      this.setPlayWallpaperIdx(idx);
      this.$toast.show("壁纸应用成功");
    },
    //累加玩游戏的次数
    addGameViewCount(game) {
      this.gvdm.lastID = game.ID;
      let num = this.gvdm.data[game.ID] || 0;
      num++;
      this.gvdm.data[game.ID] = num;
      this.saveGvdm();
    },
    //选择游戏
    gameClickEvent(g) {
      if (this.selectGameMode) {
        if (this.selectGameDict[g.ID] != null) {
          this.selectGameDict[g.ID] = null;
          delete this.selectGameDict[g.ID];
        } else {
          this.selectGameDict[g.ID] = g;
        }
        return;
      }
      this.$ws.send("game.open", {
        g: g,
      });
      this.addGameViewCount(g);
    },
    //切换播放类型
    nextMode() {
      //listLoop 列表循环,oneLoop 单个循环,random 随机
      if (this.playSettings.loopMode == "listLoop") {
        this.playSettings.loopMode = "oneLoop";
      } else if (this.playSettings.loopMode == "oneLoop") {
        this.playSettings.loopMode = "random";
      } else {
        this.playSettings.loopMode = "listLoop";
      }
      this.saveConfig();
      const dict = {
        listLoop: "列表循环",
        oneLoop: "单壁纸循环",
        random: "随机",
      };
      let mode = dict[this.playSettings.loopMode];

      this.$toast.show(mode);
    },
    //保存软件配置
    saveSoftConfig() {
      this.$ws.send("softConfig.saveConfig", { config: this.softConfig });
    },
    //设置静态文件夹路径
    async setStaticDir() {
      const res = await this.$localHttp.post("/file/selectFolder", {});
      if (res.status != 200) {
        return;
      }
      this.softConfig.staticDir = res.data;
    },
    //选择特效发生改变
    effectChangeEvent() {
      this.saveConfig();
    },
    //删除壁纸
    async deleteWallpaper() {
      const wallpaper = this.contextMenu.wallpaper.item;
      const idx = this.contextMenu.wallpaper.idx;
      const res = await this.$localHttp.post("/wallpaper/delete", {
        id: wallpaper.id,
      });
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        return;
      }
      this.wallpapers.splice(idx, 1);
    },
    async deleteGame() {
      const game = this.contextMenu.game.item;
      const idx = this.contextMenu.game.idx;
      const res = await this.$ws.send("game.remove", {
        id: game.ID,
      });
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        return;
      }
      this.gameList.splice(idx, 1);
      this.$toast.show("删除成功");
    },
    //删除文件夹
    async deleteFolder() {
      const idx = this.contextMenu.folder.idx;
      const folder = this.contextMenu.folder.item;
      const res = await this.$localHttp.post("/folder/delete", {
        folderID: folder.folderID,
      });
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        return;
      }
      this.folders.splice(idx, 1);
    },
    //设置主题图片
    async setTheme(name = "") {
      this.$ws.send("appConfig.setTheme", {
        theme: this.playSettings.theme,
        name,
      });
    },
    //添加壁纸
    async addWallpaper() {
      this.wallpaperForm.show = false;
      this.addLoading = elLoading.service({
        text: "正在添加壁纸",
        target: "#content",
      });
      if (this.wallpaperForm.type == "cutVideo") {
        if (this.cutVideo.url == "") {
          this.$toast.show("请选择视频");
          this.wallpaperForm.show = true;
          this.addLoading.close();
          return;
        }
        if (this.cutVideo.startTime == "") {
          this.$toast.show("请设置要截取的开始时间");
          this.wallpaperForm.show = true;
          this.addLoading.close();
          return;
        }
        if (this.cutVideo.endTime == "") {
          this.$toast.show("请设置要截取的截止时间");
          this.wallpaperForm.show = true;
          this.addLoading.close();
          return;
        }

        const cutVideo = {};
        Object.assign(cutVideo, this.cutVideo);

        //暂停播放
        this.$refs.cutVideo.pause();

        cutVideo.mute = this.$refs.cutVideo.muted;
        //播放器当前播放倍速及音量
        const playbackRate = this.$refs.cutVideo.playbackRate;
        const volume = this.$refs.cutVideo.volume;

        // cutVideo.st = cutVideo.st / playbackRate;
        // cutVideo.et = cutVideo.et / playbackRate;
        //从新根据倍速计算时间
        cutVideo.startTime = this.secToTime(cutVideo.st);
        cutVideo.endTime = this.secToTime(cutVideo.et);

        let setpts = 1 / playbackRate;
        setpts = Number(setpts.toFixed(5));
        //2.2版本之前
        cutVideo.param = `-filter:a "volume=${volume},atempo=${playbackRate}" -filter:v "setpts=${setpts}*PTS"`;

        if (Number(this.localVer) >= 2.2) {
          if (this.cutVideo.reverse) {
            if (volume == 0) {
              cutVideo.param = `-filter_complex "[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v1];[v1]reverse[v2];[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v3];[v3][v2]concat=n=2:v=1[rv0];[rv0]setpts=${setpts}*PTS[v]" -map "[v]"`;
            } else {
              cutVideo.param = `-filter_complex "[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v1];[v1]reverse[v2];[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v3];[0:a]atrim=start=${cutVideo.st}:end=${cutVideo.et},asetpts=PTS-STARTPTS[a1];[0:a]atrim=start=${cutVideo.st}:end=${cutVideo.et},asetpts=PTS-STARTPTS[a2];[v3][a1][v2][a2]concat=n=2:v=1:a=1[rv0][ra0];[rv0]setpts=${setpts}*PTS[v];[ra0]atempo=${playbackRate},volume=${volume}[a]" -map "[v]" -map "[a]"`;
            }
          } else {
            if (volume == 0) {
              cutVideo.param = `-filter_complex "[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v1];[v1]setpts=${setpts}*PTS[v]" -map "[v]"`;
            } else {
              cutVideo.param = `-filter_complex "[0:v]trim=start=${cutVideo.st}:end=${cutVideo.et},setpts=PTS-STARTPTS[v1];[0:a]atrim=start=${cutVideo.st}:end=${cutVideo.et},asetpts=PTS-STARTPTS[a1];[v1]setpts=${setpts}*PTS[v];[a1]atempo=${playbackRate},volume=${volume}[a]" -map "[v]" -map "[a]"`;
            }
          }
        }

        this.wallpaperForm.data = JSON.stringify(cutVideo);
      }

      const res = await this.$localHttp.post("/wallpaper/add", {
        wallpaper: JSON.stringify(this.wallpaperForm),
      });
      this.addLoading.close();
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        this.wallpaperForm.show = true;
        return;
      }
      // ElMessage({
      //   message: "添加成功",
      //   type: "success",
      //   duration: 2000,
      // });
      this.$toast.show(`壁纸添加成功，点击壁纸封面可设置为壁纸。`, 3000);
      this.wallpaperForm.type = "imgOrVideo";
      this.wallpaperForm.url = [];
      this.wallpaperForm.name = "";
      await this.getWallpaperByFolderID({
        folderID: this.wallpaperForm.folderID,
      });
      //if (this.playSettings.lastPlayWallpaperID == this.wallpaperForm.folderID) {
      // this.playWallpapers = this.wallpapers;
      //}
    },
    //添加文件夹
    async addFolder() {
      if (this.folderForm.folderName == "") {
        ElMessage({
          message: "请输入分类名称",
          type: "error",
          duration: 2000,
        });
        return;
      }
      for (const item of this.folders) {
        if (item.folderName == this.folderForm.folderName) {
          ElMessage({
            message: "分类名称重复",
            type: "error",
            duration: 2000,
          });
          return;
        }
      }
      if (this.folderForm.folderID != null) {
        this.renewFolder();
        return;
      }
      const res = await this.$localHttp.post("/folder/add", {
        folder: JSON.stringify(this.folderForm),
      });
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        return;
      }

      this.$toast.show("添加成功");
      this.folderForm.show = false;
      this.folderForm.folderName = "";
      this.getFolderList();
    },
    //修改文件夹
    async renewFolder() {
      const folder = {};
      Object.assign(folder, this.folderForm.folder);
      folder.folderName = this.folderForm.folderName;
      console.log(
        {
          folder: JSON.stringify(folder),
        },
        this.folderForm.folder
      );
      const res = await this.$localHttp.post("/folder/renew", {
        folder: JSON.stringify(folder),
      });
      if (res.status != 200) {
        ElMessage({
          message: res.message,
          type: "error",
          duration: 5000,
        });
        return;
      }
      this.$toast.show("修改成功");
      this.folderForm.folder.folderName = folder.folderName;
      this.folderForm.folder = null;
      this.folderForm.show = false;
      this.folderForm.folderID = null;
      this.folderForm.folderName = "";
    },
    //获取文件夹列表
    async getFolderList() {
      const res = await this.$localHttp.post("/folder/getList", {});
      this.folders = res.data;
    },
    //获取文件夹下的壁纸
    async getWallpaperByFolderID(folder) {
      //选择文件夹模式
      if (this.selectFolderMode) {
        if (this.selectFolderDict[folder.folderID] == null) {
          this.selectFolderDict[folder.folderID] = true;
        } else {
          this.selectFolderDict[folder.folderID] =
            !this.selectFolderDict[folder.folderID];
        }
        return;
      }
      this.selectFolderID = folder.folderID;
      this.showType = "wallpaper";
      this.wallpaperForm.folderID = folder.folderID;
      const res = await this.$localHttp.post("/wallpaper/getByFolderID", {
        folderID: folder.folderID,
      });
      this.wallpapers = res.data;
    },
    //设置窗口状态
    setWindowState(state) {
      this.$ws.send("mainWindow.setWindowState", {
        state,
      });
    },
    //播放、暂停
    play() {
      let oldValue = this.playSettings.play;
      this.playSettings.play = oldValue == 1 ? 0 : 1;
      this.saveConfig();
    },
    //上一张
    prev() {
      let val = this.playWallpaperIdx - 1;
      if (val < 0) {
        val = this.playWallpapers.length - 1;
      }
      // this.playWallpaperIdx = val;
      this.setPlayWallpaperIdx(val);
    },
    //下一张
    next(playEnd = false) {
      //播放结束的自动下一张
      if (playEnd == true) {
        if (this.playSettings.loopMode == "oneLoop") {
          let v = this.playWallpaperIdx;
          // this.playWallpaperIdx = v;
          this.setPlayWallpaperIdx(v);
          return;
        } else if (this.playSettings.loopMode == "random") {
          this.randomPlay();
          return;
        }
      }
      let val = this.playWallpaperIdx + 1;
      if (val > this.playWallpapers.length - 1) {
        val = 0;
      }
      // this.playWallpaperIdx = val;
      this.setPlayWallpaperIdx(val);
    },
    //自动播放
    autoPlay() {
      clearInterval(this.autoPlayTimer);
      clearInterval(addTimeTimer);
      this.autoPlayTimer = setInterval(() => {
        //暂停 或者 单个循环 直接返回
        if (
          this.playSettings.play != 1 ||
          this.playSettings.loopMode == "oneLoop"
        ) {
          return;
        }
        //视频不受时间影影响播放完成才x下一个
        if (
          this.playWallpapers[this.playWallpaperIdx] == null ||
          (this.playWallpapers[this.playWallpaperIdx].type == "video" &&
            !this.playSettings.timeApplyVideo)
        ) {
          return;
        }
        switch (this.playSettings.loopMode) {
          case "listLoop":
            {
              this.next();
            }
            break;
          case "random":
            {
              this.randomPlay();
            }
            break;
        }
      }, this.playSettings.time * 1000);
      this.playTime = 0;
      addTimeTimer = setInterval(() => {
        this.playTime++;
      }, 1000);
    },
    //随机播放
    randomPlay() {
      let max = this.playWallpapers.length - 1;
      var val = Math.floor(Math.random() * (max + 1));
      // this.playWallpaperIdx = val;
      this.setPlayWallpaperIdx(val);
    },
    //设置是否静音
    mute(v) {
      this.playSettings.mute = v;
      this.saveConfig();
    },
    //获取设置配置
    async getConfig() {
      //需要等待背景页面加载完成，不然发送数据也收不到
      const waitLoading = elLoading.service({
        text: "等待背景加载...",
        target: "#content",
      });
      await this.waitAppReady();
      waitLoading.close();

      const res = await this.$localHttp.post("/appConfig/getConfig", {});
      this.playSettings.theme = res.data.theme;
      this.playSettings.startup = res.data.startup;
      this.playSettings.effects = JSON.parse(res.data.effects);
      this.playSettings.scrEnable = res.data.scrEnable;
      this.playSettings.scrTime = res.data.scrTime;
      if (res.data.timeApplyVideo == null) {
        this.playSettings.timeApplyVideo =
          localStorage.getItem("timeApplyVideo") == "1";
      }
      let wallpaperIdx = null;
      //加载上次的播放的
      if (
        res.data.lastSelectFolderID != null &&
        res.data.lastSelectFolderID != "" &&
        res.data.lastPlayWallpaperID != null &&
        res.data.lastPlayWallpaperID != ""
      ) {
        const wrs = await this.$localHttp.post("/wallpaper/getByFolderID", {
          folderID: res.data.lastSelectFolderID,
        });
        this.playWallpapers = wrs.data;

        for (let i = 0; i < wrs.data.length; i++) {
          if (wrs.data[i].id == res.data.lastPlayWallpaperID) {
            wallpaperIdx = i;
          }
        }
      }
      console.log("配置：", res);
      this.playSettings.play = res.data.play;
      this.playSettings.time = res.data.time;
      this.playSettings.volume = res.data.volume;
      this.playSettings.loopMode = res.data.loopMode;
      this.playSettings.mute = res.data.mute;
      this.playSettings.lastSelectFolderID = res.data.lastSelectFolderID;
      this.playSettings.lastPlayWallpaperID = res.data.lastPlayWallpaperID;
      this.playSettings.thread = res.data.thread;

      // await new Promise((resolve) => {
      //   setTimeout(resolve, 2000);
      // });
      if (wallpaperIdx != null) {
        this.setPlayWallpaperIdx(wallpaperIdx);
      }
    },
    //保存配置
    async saveConfig(showMsg = false) {
      const form = {};
      Object.assign(form, this.playSettings);
      form.effects = JSON.stringify(form.effects);
      this.$ws.send(`appConfig.save`, { config: form });
      if (showMsg) {
        this.$toast.show("保存成功");
        console.log("保存配置");
      }
    },
    async selectImage(startIdx = 0, multi = false) {
      //先隐藏这个表单
      this.wallpaperForm.show = false;
      this.selectFileLoading = elLoading.service({
        text: `正在选择文件`,
        target: "#content",
      });
      const res = await this.$localHttp.post("/file/selectFile", { multi });
      if (res.status != 200) {
        this.selectFileLoading.close();
        return;
      }
      //显示表单
      this.wallpaperForm.show = true;
      console.log(res);
      for (let i = 0; i < res.data.length; i++) {
        if (this.wallpaperForm.url[startIdx + i] == null) {
          this.wallpaperForm.url.push(res.data[i]);
          continue;
        }
        this.wallpaperForm.url[startIdx + i] = res.data[i];
      }
      this.selectFileLoading.close();
    },
    mousedown(e) {
      if (e.target.id != "head") {
        return;
      }
      click = true;
      x = e.clientX;
      y = e.clientY;
    },
    mouseup() {
      click = false;
      x = 0;
      y = 0;
    },
    mousemove(e) {
      if (click) {
        this.$ws.send("mainWindow.moveWindow", {
          x: e.clientX - x,
          y: e.clientY - y,
        });
      }
    },
    setPlayWallpaperIdx(val) {
      this.playWallpaperIdx = val;
      const w = this.playWallpapers[this.playWallpaperIdx];
      this.$ws.send("broadcast.broadcastMessage", {
        type: "playIdxChange",
        data: w.id,
      });

      // if (w.id != -1) {
      this.playSettings.lastPlayWallpaperID = w.id;
      this.playSettings.lastSelectFolderID = w.folderID;
      this.saveConfig();
      // }

      this.autoPlay();
    },
    //检查更新
    async checkUpdate() {
      const localVer = await this.$ws.send("version.getVersion", {});
      let verURL = `http://localhost:12009/version.txt`;
      if (process.env.NODE_ENV === "development") {
        verURL = `${location.protocol}//${location.hostname}:${location.port}/version.txt`;
      }
      this.localVer = localVer;
      const onlineVer = await this.$localHttp.get(verURL, {}, "text");
      this.onlineVer = onlineVer;
      if (onlineVer == localVer) {
        //判断是否显示使用引导
        {
          let isShow = localStorage.getItem("showTour");
          if (isShow == null || isShow == "") {
            this.showTourDialog = true;
          }
          // localStorage.setItem("showTour", 1);
        }
        return;
      }
      let remarkURL = `http://localhost:12009/versionRemark.txt`;
      if (process.env.NODE_ENV === "development") {
        remarkURL = `${location.protocol}//${location.hostname}:${location.port}/versionRemark.txt`;
      }
      this.updateRemark = await this.$localHttp.get(remarkURL, {}, "text");
      this.showUpdate = true;
    },
    //下载更新
    async downloadUpdate() {
      this.showUpdate = false;

      if (Number(this.localVer) < 2.1) {
        window.open(`https://wallpapersoft.com/dgscSetup${this.onlineVer}.exe`);
      } else {
        let res = await this.$ws.send("file.download", {
          source: `https://wallpapersoft.com/dgscSetup${this.onlineVer}.exe`,
          name: `软件更新v${this.onlineVer}`,
          savePath: "",
        });
        res = JSON.parse(res);
        if (res.state != 1) {
          this.$toast.show("更新失败，请联系开发者", 10000);
          return;
        }
        this.$toast.show("新版本下载完成。若未自动打开，请手动安装。", 10000);
        await this.$ws.send("file.open", {
          filePath: res.source.savePath,
        });
      }
    },
    //登录
    async login() {
      const code = await this.$ws.send("system.getID", {});
      this.$serws.send("license.verify", { code });
    },
    //软件准备好了
    WSE_appReady() {
      this.appReady = true;
    },
    //视频播放结束触发
    WSE_videoPlayEnded() {
      console.log("视频播放结束");
      if (this.playSettings.play == 1) {
        this.next(true);
      }
    },
    WSE_createCover(fileName) {
      if (this.selectFileLoading != null) {
        this.selectFileLoading.setText(`正在创建“${fileName}”的封面`);
      }
    },
    WSE_transcoder(fileName) {
      if (this.addLoading != null) {
        this.addLoading.setText(`正在转码“${fileName}”`);
      }
    },
    WSE_tp(msg) {
      if (this.addLoading != null) {
        this.addLoading.setText(msg);
      }
    },
    WSE_copyFile(fileName) {
      if (this.addLoading != null) {
        this.addLoading.setText(`正在拷贝文件“${fileName}”`);
      }
    },
    WSE_nextFormApp() {
      this.next();
    },
    WSE_preFromApp() {
      this.prev();
    },
    WSE_muteFormApp() {
      const val = this.playSettings.mute == 1 ? 0 : 1;
      this.mute(val);
    },
    async WSE_dp(data) {
      data = JSON.parse(data);
      if (!downloadDict[data.id]) {
        this.downloadData.list.splice(0, 0, data);
        downloadDict[data.id] = this.downloadData.list[0];
      } else {
        if (data.state == 1 && data.source != null) {
          this.$toast.show(`“${data.source.name}”下载成功`);
        } else if (data.state == 2) {
          this.$toast.show(`“${data.source.name}”下载失败或取消。`);
        }
        const item = downloadDict[data.id];
        item.cover = data.cover;
        item.source = data.source;
        item.state = data.state;
        item.message = data.message;
        return;
      }
    },

    async WSE_iw(filePath) {
      if (/[\s\S]+?\.games$/g.test(filePath)) {
        this.inputGame.filePath = filePath;
        this.inputGame.password = "";
        this.inputGameFile();
      } else {
        this.input.filePath = filePath;
        this.input.password = "";
        this.inputFile();
      }
    },

    async WSE_opm(msg) {
      try {
        inputLoading.setText(msg);
      } catch (ex) {
        console.log(ex);
      }
    },
    async WSE_scrInit() {
      this.$ws.send("broadcast.broadcastMessage", {
        type: "effectChange",
        data: JSON.stringify(this.playSettings.effects),
      });
      this.$ws.send("broadcast.broadcastMessage", {
        type: "scrListChange",
        data: JSON.stringify(this.playWallpapers),
      });
      setTimeout(() => {
        const w = this.playWallpapers[this.playWallpaperIdx];
        this.$ws.send("broadcast.broadcastMessage", {
          type: "playIdxChange",
          data: w.id,
        });
      }, 20);
    },

    //服务器发来弹窗消息
    SERWSE_dialog(data) {
      data = JSON.parse(data);
      const { title, width, content, btnText } = data;
      this.dialog.width = width;
      this.dialog.title = title;
      this.dialog.content = content;
      this.dialog.btnText = btnText;
      this.dialog.show = true;
    },
    //服务器发来刷新消息
    SERWSE_refshApp() {
      location.reload();
    },
  },
  watch: {
    showPage: function (val) {
      localStorage.setItem("lastPage", val);
    },
    playWallpapers: function (val) {
      this.$ws.send("broadcast.broadcastMessage", {
        type: "playListChange",
        data: JSON.stringify(val),
      });
    },
    "playSettings.play": function (val) {
      this.autoPlay();
      this.$toast.show(val == 1 ? "自动播放" : "暂停自动播放");
    },
    "playSettings.time": function () {
      this.autoPlay();
    },
    "playSettings.volume": function (val) {
      this.$ws.send("broadcast.broadcastMessage", {
        type: "volumeChange",
        data: val,
      });
    },
    "playSettings.mute": function (val) {
      this.$ws.send("broadcast.broadcastMessage", {
        type: "muteChange",
        data: val,
      });
      this.$toast.show(val == 1 ? "静音" : "取消静音");
    },
    "playSettings.effects": function (val) {
      console.log("特效发生改变");
      this.$ws.send("broadcast.broadcastMessage", {
        type: "effectChange",
        data: JSON.stringify(val),
      });
    },
    "playSettings.timeApplyVideo": function (val) {
      localStorage.setItem("timeApplyVideo", val == true ? "1" : "0");
    },
    "wallpaperForm.type": function (val) {
      this.wallpaperForm.name = "";
      if (val == "url") {
        this.wallpaperForm.url = [
          {
            url: "",
            source: "",
            type: "url",
            druation: "",
          },
        ];
      } else if (val == "imgOrVideo") {
        this.wallpaperForm.url = [];
      } else if (val == "combine") {
        this.wallpaperForm.url = [];
        this.wallpaperForm.combineName = null;
      } else if (val == "cutVideo") {
        this.cutVideo = {
          url: "",
          startTime: "",
          endTime: "",
          mute: false,
          play: false,
          st: 0,
          et: 100,
          loop: false,
          param: "",
          volume: 0,
          playbackRate: 1.0,
          reverse: false,
        };
      }
    },
    "wallpaperForm.combineName": function (val) {
      if (val == null) {
        return;
      }
      for (const c of combineConfig) {
        if (c.name == val) {
          this.wallpaperForm.combineImgCount = c.combineImgCount;
          this.wallpaperForm.name = c.cName;
          break;
        }
      }
      let url = [];
      for (let i = 0; i < this.wallpaperForm.combineImgCount; i++) {
        url.push({
          url: "",
          source: "",
          type: "img",
          druation: "",
        });
      }
      this.wallpaperForm.url = url;
    },
  },
  async mounted() {
    //跳转上次的页面
    {
      let lastPage = localStorage.getItem("lastPage") || "storeWallpaper";
      if (lastPage == "storeWallpaper") {
        await this.getStoreTypeList();
      }
    }
    //订阅消息
    {
      this.$ws.send("broadcast.subscribe", {});
      this.$ws.subscribe("broadcast.subscribe", async (res) => {
        const method = `WSE_${res.data.type}`;
        if (this[method] != null && typeof this[method] == "function") {
          this[method](res.data.data);
        }
      });
      this.$ws.send("broadcast.broadcastMessage", {
        type: "settings",
        data: JSON.stringify(this.playSettings),
      });

      //通知主窗口准备就绪
      this.$ws.send("appState.initApp", {});
    }
    //获取文件夹列表
    this.getFolderList();
    //获取配置
    this.getConfig();

    this.getGameList();

    //设置主题的通知结果
    {
      this.$ws.subscribe("appConfig.setTheme", async (res) => {
        this.playSettings.theme = res.data;
        this.saveConfig();
      });
    }
    //获取软件交互
    {
      this.$ws.send("softConfig.getConfig", {});
      this.$ws.subscribe("softConfig.getConfig", async (res) => {
        this.softConfig = res.data;
      });
      let loadingService = null;
      //订阅保存软件配置消息
      this.$ws.subscribe("softConfig.saveConfig", async (res) => {
        if (res.data.status == "startMove") {
          if (loadingService == null) {
            loadingService = elLoading.service({
              text: `正在转移文件${res.data.file}`,
            });
          } else {
            loadingService.setText(`正在转移文件${res.data.file}`);
          }
        }
        if (res.data.status == "error") {
          loadingService.close();
          loadingService = null;
          this.$alert(res.data.message, "转移失败", {
            confirmButtonText: "确定",
          });
        }
        if (res.data.status == "endMove") {
          loadingService.close();
          loadingService = null;
          this.$alert("程序需要重启后才能正常使用", "数据转移完成", {
            confirmButtonText: "确定",
            callback: () => {
              elLoading.service({
                text: `正在重启程序`,
              });
              this.$ws.send("mainWindow.restart", {});
            },
          });
        }
      });
    }

    //获取电脑CPU核心数量
    {
      this.cpuCount = await this.$ws.send("system.getCpuCount", {});
    }

    //检查更新
    {
      this.checkUpdate();
    }

    //订阅服务器端消息
    {
      const subscribe = () => {
        this.$serws.send("broadcast.subscribe", {});
        this.$serws.subscribe("broadcast.subscribe", async (res) => {
          const method = `SERWSE_${res.data.type}`;
          if (this[method] != null && typeof this[method] == "function") {
            this[method](res.data.data);
          }
        });
        //10秒ping一次
        setInterval(() => {
          this.$serws.sendPing();
        }, 10000);
      };
      subscribe();
      this.$serws.connNotify(() => {
        console.log("开始验证");
        this.login();
      });
      this.$serws.connNotify(subscribe);
    }

    //登录
    {
      this.login();
    }

    //窗口拖动
    {
      window.removeEventListener("mousedown", this.mousedown);
      window.removeEventListener("mouseup", this.mouseup);
      window.removeEventListener("mousemove", this.mousemove);
      window.addEventListener("mousedown", this.mousedown);
      window.addEventListener("mouseup", this.mouseup);
      window.addEventListener("mousemove", this.mousemove);
    }
  },
  computed: {
    autoStart() {
      return this.playSettings.startup == 1;
    },
    downloadCountInfo() {
      const dict = {
        0: 0,
        1: 0,
        2: 0,
      };
      for (const item of this.downloadData.list) {
        dict[item.state]++;
      }
      return dict;
    },
    //预览图片
    storePreviewSrcList() {
      var arr = [];
      const imgServer = this.store.imgServer;
      for (const item of this.store.list) {
        if (item.type == "img") {
          arr.push(`${imgServer}${item.source}`);
        } else {
          arr.push(`${imgServer}${item.cover}`);
        }
      }
      return arr;
    },
    //选择的文件夹名称
    selectFolderName() {
      let name = "";
      let fid =
        this.selectFolderID == null
          ? this.playSettings.lastSelectFolderID
          : this.selectFolderID;
      if (fid == null || fid == "" || fid == -1) {
        return name;
      }
      for (const f of this.folders) {
        if (f.folderID == fid) {
          name = f.folderName;
        }
      }
      return name;
    },
    //剩余时间
    surplusTime() {
      const w = this.wallpapers[this.playWallpaperIdx];
      if (
        this.playSettings.loopMode == "oneLoop" ||
        (w != null &&
          w.type == "video" &&
          this.playSettings.timeApplyVideo == false)
      ) {
        return `∞`;
      }
      let c = this.playSettings.time - this.playTime;
      c = c < 0 ? 0 : c;
      return `${c.toFixed(0)} s`;
    },
    //游戏显示数据
    viewGameList() {
      let list = this.gameList;
      let str = this.gameSearchText;
      let gvdm = this.gvdm;
      let arr = [];
      for (const item of list) {
        if (`${item.gameName}`.indexOf(str) > -1 || str == "") {
          arr.push(item);
        }
      }
      arr.sort((a, b) => {
        //两个游戏都置顶
        if (gvdm.tops[a.ID] != null && gvdm.tops[b.ID] != null) {
          return gvdm.tops[b.ID] - gvdm.tops[a.ID];
        }
        //a置顶b没置顶
        if (gvdm.tops[a.ID] != null && gvdm.tops[b.ID] == null) {
          return -1;
        }
        //b置顶a没置顶
        if (gvdm.tops[a.ID] == null && gvdm.tops[b.ID] != null) {
          return 1;
        }

        if (b.ID == gvdm.lastID) {
          return 1;
        }
        if (a.ID == gvdm.lastID) {
          return -1;
        }
        //都没玩过
        if (gvdm.data[a.ID] == null && gvdm.data[b.ID] == null) {
          return 0;
        }
        //都玩过
        if (gvdm.data[a.ID] != null && gvdm.data[b.ID] != null) {
          return Number(gvdm.data[b.ID]) - Number(gvdm.data[a.ID]);
        }
        //a没玩过
        if (gvdm.data[a.ID] == null) {
          return 1;
        }
        //b没玩过
        if (gvdm.data[b.ID] == null) {
          return -1;
        }
      });

      if (list.length == 0) {
        this.$toast.show("您还未添加任何游戏");
      } else if (arr.length == 0 && str != "") {
        this.$toast.show("搜索无结果");
      }

      return arr;
    },
  },
};
</script>
<style src="@/assets/css/app.css" scoped></style>
<style src="@/assets/css/combine.css" scoped></style>
