<template>
  <div
    id="taskbarPage"
    style="color: red; cursor: pointer; background: white"
    @click="aaa('ads')"
  >
    -webkit-box-reflect 倒影效果
  </div>
</template>
<script>
export default {
  name: "taskbarPage",
  data() {
    return {};
  },
  methods: {
    aaa(msg) {
      alert(msg);
    },
  },
};
</script>
