<!-- 官网 -->
<template>
  <div class="w100 h100 col" style="background: rgb(57, 81, 105)">
    <div id="head" class="row between">
      <div id="headLeft" class="row vCenter hCenter">
        <img id="logo" src="/favicon.ico" alt="" />
        <div id="title">
          动感视窗 · 个性化您的电脑桌面，让工作、生活更富有创意和色彩！
        </div>
      </div>
      <div id="headRight" class="row vCenter hCenter">
        <a href="https://image.nobugltd.com" id="imgDownload">
          <div>壁纸下载</div>
        </a>
        <!-- <el-popover title="" :width="660" trigger="click"> -->
        <el-popover title="" :width="220" trigger="click">
          <template #reference>
            <div id="qrcode">联系我们</div>
          </template>
          <div id="qrcodeList" class="row">
            <div class="qrcodeItem col vCenter hCenter">
              <img class="qrcode" src="@/assets/images/wechat2.png" />
              <div class="qrcodeTitle">联系开发者</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div id="content" class="w100 h100 row full hCenter vCenter">
      <div class="col ttbKF" id="contentLeft">
        <div class="line1">
          让您的桌面焕然一新！探索我们的桌面壁纸软件，轻松将心仪的图片和视频设为壁纸。
        </div>
        <div class="line2">
          探索无限可能！我们还提供丰富的自定义设置和扩展功能。激发您的创造力，发挥想象力，打造独一无二的电脑桌面！
        </div>
        <div class="line2">
          这款电脑壁纸软件拥有强大的功能，支持将图片、动图、视频及网页设置为桌面背景。视频功能尤为突出，可自由剪辑、调整播放速度，甚至实现倒放效果。轻松打造个性化桌面，让您的桌面与众不同！
        </div>
        <div class="line2">
          支持自定义剪辑、打包分享、分享更方便的与自己小伙伴分享喜爱的壁纸。
        </div>
        <div class="line2">
          <!-- 本产品基于Microsoft DotNet 6平台开发，第三方组件引用包含
          <a href="https://element-plus.org/zh-CN/">Element-Plus</a>
          <a href="https://learn.microsoft.com/zh-cn/microsoft-edge/webview2/">
            Webview2
          </a>
          <a href="https://docs.microsoft.com/dotnet/standard/data/sqlite/">
            Microsoft.Data.Sqlite。
          </a>
          CDN加速及云计算服务使用
          <a href="https://cloud.baidu.com/"> 百度智能云服务 </a>
          <a href="https://activity.huaweicloud.com/"> 华为云 </a>
          。 -->
        </div>
        <!-- <div class="line2" style="color: orange">软件功能永久免费！</div> -->
        <div class="line2">
          <!-- 支持 Windows
          10及以上系统。由于软件未签名，部分杀毒软件可能会误拦截、报毒，请认准唯一官网下载。有任何疑问请及时联系作者！ -->
        </div>
        <div class="row hCenter vCenter">
          <a :href="`/dgscSetup${ver}.exe`">
            <div id="downloadBtn">立即下载 v{{ ver }}</div>
          </a>
        </div>
      </div>
      <img
        id="productImg"
        class="bttKF"
        v-if="showImg == 0"
        src="@/assets/images/home0.png"
        alt=""
      />
      <img
        id="productImg"
        class="bttKF"
        v-if="showImg == 1"
        src="@/assets/images/home1.png"
        alt=""
      />
      <img
        id="productImg"
        class="bttKF"
        v-if="showImg == 2"
        src="@/assets/images/home2.png"
        alt=""
      />
    </div>
    <div class="w100 row vCenter hCenter" id="foot">
      <a href="https://www.nobugltd.com"> Copyright © 2024 吾八戈软件工作室 </a>
      <a href="https://beian.miit.gov.cn/">桂ICP备2023003809号</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "indexPage",
  data() {
    return {
      ver: "1.0",
      showImg: Math.floor(Math.random() * 3),
    };
  },
  async mounted() {
    let verURL = `${location.protocol}//${location.hostname}/version.txt`;
    if (process.env.NODE_ENV === "development") {
      verURL = `${location.protocol}//${location.hostname}:${location.port}/version.txt`;
    }
    const res = await this.$localHttp.get(verURL, {}, "text");
    this.ver = res;
  },
};
</script>
<style scoped src="@/assets/css/index.css"></style>
