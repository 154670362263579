<template>
  <div id="gamePage" class="col w100 h100">
    <!-- 顶部菜单 -->
    <div id="navbar" class="row w100 between">
      <div class="navbarLeft row full">
        <!-- <div class="navItem row vCenter" @click="$refs.nes.start">
          <el-icon><Compass /></el-icon>
          <span class="navItemTxt">开始</span>
        </div> -->

        <!-- <div class="navItem row vCenter" @click="$refs.nes.stop">
          <el-icon><SwitchButton /></el-icon>
          <span class="navItemTxt">停止</span>
        </div> -->
        <div class="navItem row vCenter" @click="$refs.nes.pause">
          <el-icon><CoffeeCup /></el-icon>
          <span class="navItemTxt">暂停F1</span>
        </div>
        <div class="navItem row vCenter" @click="$refs.nes.play">
          <el-icon><VideoPlay /></el-icon>
          <span class="navItemTxt">恢复F2</span>
        </div>
        <div
          class="navItem row vCenter"
          @click="$refs.nes.save('document_' + game.id)"
        >
          <el-icon><VideoCamera /></el-icon>
          <span class="navItemTxt">存档F3</span>
        </div>
        <div
          class="navItem row vCenter"
          @click="$refs.nes.load('document_' + game.id)"
        >
          <el-icon><VideoCameraFilled /></el-icon>
          <span class="navItemTxt">读档F4</span>
        </div>
        <div class="navItem row vCenter" @click="$refs.nes.reset">
          <el-icon><Refresh /></el-icon>
          <span class="navItemTxt">重启F5</span>
        </div>
        <div
          class="navItem row vCenter"
          @click="
            showSettings = true;
            $refs.nes.pause();
          "
        >
          <el-icon><Tools /></el-icon>
          <span class="navItemTxt">设置F8</span>
        </div>
      </div>
      <div class="navbarRight row shrink">
        <div class="slider-demo-block">
          <div class="navItem row vCenter" style="width: 100px">
            <el-slider
              size="small"
              @change="saveConfig"
              v-model="settings.gain"
            />
            <span class="navItemTxt shrink" style="margin-left: 0.5rem">
              音量
            </span>
          </div>
        </div>
      </div>
    </div>
    <div ref="nesContainer" class="row full w100 h100 vCenter hCenter">
      <NesVue
        id="nes"
        ref="nes"
        class="full w100 h100"
        :width="settings.width"
        :height="settings.height"
        :auto-start="false"
        label="开始游戏"
        :url="'http://localhost:12008/static/games/' + game.file"
        :p1="settings.p1"
        :p2="settings.p2"
        :gain="settings.gain"
        :turbo="25"
        @saved="saved"
        @loaded="loaded"
        @removed="removed"
        @error="error"
        @success="loadSuccess"
      ></NesVue>
    </div>
    <!-- 设置页面 -->
    <div v-show="showSettings" class="col w100 h100" id="settingsPanel">
      <div class="row full around">
        <el-form>
          <el-form-item label-width="5rem" label="玩家1按键"> </el-form-item>
          <el-form-item label-width="5rem" label="选择">
            <input
              @keydown="setKey(1, 'SELECT', $event)"
              placeholder="按下按键"
              v-model="settings.p1.SELECT"
              inputmode="numeric"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="开始">
            <input
              @keydown="setKey(1, 'START', $event)"
              placeholder="按下按键"
              v-model="settings.p1.START"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="上">
            <input
              @keydown="setKey(1, 'UP', $event)"
              placeholder="按下按键"
              v-model="settings.p1.UP"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="下">
            <input
              @keydown="setKey(1, 'DOWN', $event)"
              placeholder="按下按键"
              v-model="settings.p1.DOWN"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="左">
            <input
              @keydown="setKey(1, 'LEFT', $event)"
              placeholder="按下按键"
              v-model="settings.p1.LEFT"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="右">
            <input
              @keydown="setKey(1, 'RIGHT', $event)"
              placeholder="按下按键"
              v-model="settings.p1.RIGHT"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="A">
            <input
              @keydown="setKey(1, 'A', $event)"
              placeholder="按下按键"
              v-model="settings.p1.A"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="B">
            <input
              @keydown="setKey(1, 'B', $event)"
              placeholder="按下按键"
              v-model="settings.p1.B"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="C">
            <input
              @keydown="setKey(1, 'C', $event)"
              placeholder="按下按键"
              v-model="settings.p1.C"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="D">
            <input
              @keydown="setKey(1, 'D', $event)"
              placeholder="按下按键"
              v-model="settings.p1.D"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
        </el-form>
        <el-form>
          <el-form-item label-width="5rem" label="玩家2按键"> </el-form-item>
          <el-form-item label-width="5rem" label="上">
            <input
              @keydown="setKey(2, 'UP', $event)"
              placeholder="按下按键"
              v-model="settings.p2.UP"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="下">
            <input
              @keydown="setKey(2, 'DOWN', $event)"
              placeholder="按下按键"
              v-model="settings.p2.DOWN"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="左">
            <input
              @keydown="setKey(2, 'LEFT', $event)"
              placeholder="按下按键"
              v-model="settings.p2.LEFT"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="右">
            <input
              @keydown="setKey(2, 'RIGHT', $event)"
              placeholder="按下按键"
              v-model="settings.p2.RIGHT"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="A">
            <input
              @keydown="setKey(2, 'A', $event)"
              placeholder="按下按键"
              v-model="settings.p2.A"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="B">
            <input
              @keydown="setKey(2, 'B', $event)"
              placeholder="按下按键"
              v-model="settings.p2.B"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="C">
            <input
              @keydown="setKey(2, 'C', $event)"
              placeholder="按下按键"
              v-model="settings.p2.C"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="D">
            <input
              @keydown="setKey(2, 'D', $event)"
              placeholder="按下按键"
              v-model="settings.p2.D"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
          <el-form-item label-width="5rem" label="按键频率">
            <el-input-number
              size="small"
              v-model="settings.turbo"
              :step="5"
              :min="5"
              :max="25"
              @compositionstart="compositionState = true"
              @compositionend="compositionState = false"
            />
          </el-form-item>
        </el-form>
      </div>
      <div
        class="row w100 vCenter hCenter full"
        style="
          align-items: flex-start;
          box-sizing: border-box;
          min-height: 120px;
          padding: 20px;
        "
      >
        <el-button type="primary" @click="resetSettings">
          恢复默认设置
          <el-icon class="el-icon--right"><RefreshLeft /></el-icon>
        </el-button>
        <el-button type="primary" @click="saveConfig(true)">
          保存设置(ctrl+S)
          <el-icon class="el-icon--right"><CopyDocument /></el-icon>
        </el-button>
      </div>
    </div>
    <!-- 使用引导询问对话框 -->
    <el-dialog v-model="showTourDialog" title="使用引导" width="500" center>
      <div style="padding: 0.3rem">
        <div style="color: orange">默认按键：</div>
        <div>选择：左边数字1</div>
        <div>开始：左边数字2</div>
        <div>上：W</div>
        <div>下：S</div>
        <div>左：A</div>
        <div>右：D</div>
        <div>A ：J</div>
        <div>B ：K</div>
        <div>C ：U</div>
        <div>D ：I</div>
      </div>
      <div style="color: orange; padding: 0.3rem">玩家2、按键设置可按F8。</div>
      <div style="padding: 0.3rem">
        玩法、资源分享、使用问题等，可联系微信“
        <span style="color: blue"> nobugltd </span>
        ”加群交流。
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="warning" @click="showTourDialog = false"
            >下次再看</el-button
          >
          <el-button type="success" @click="confirmTourEvent">
            不再显示
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style src="@/assets/css/game.css" scoped></style>
<script>
let isInitialResize = true;
import { NesVue } from "nes-vue";
export default {
  name: "gamePage",
  components: {
    NesVue,
  },
  data() {
    return {
      showTourDialog: false,
      compositionState: false,
      showSettings: false,
      game: {
        file: "",
        id: null,
        cover: "",
        name: "",
      },
      settings: {
        windowWidth: 800,
        windowHeight: 780,
        windowX: 0,
        windowY: 0,
        width: 500,
        height: 500,
        //游戏音量，介于[0, 100]之间。
        gain: 50,
        //连发键每秒频率 介于[5, 25]之间。
        turbo: 25,
        //玩家1
        p1: {
          UP: "KeyW",
          DOWN: "KeyS",
          LEFT: "KeyA",
          RIGHT: "KeyD",
          A: "KeyJ",
          B: "KeyK",
          C: "KeyU",
          D: "KeyI",
          SELECT: "Digit1",
          START: "Digit2",
        },
        //玩家2
        p2: {
          UP: "ArrowUp",
          DOWN: "ArrowDown",
          LEFT: "ArrowLeft",
          RIGHT: "ArrowRight",
          A: "Numpad1",
          B: "Numpad2",
          C: "Numpad4",
          D: "Numpad5",
        },
      },
    };
  },
  methods: {
    //不再显示引导对话框
    confirmTourEvent() {
      this.showTourDialog = false;
      localStorage.setItem("showTourDialog", "1");
    },
    //重置设置
    resetSettings() {
      this.settings = {
        windowWidth: this.settings.windowWidth,
        windowHeight: this.settings.windowHeight,
        windowX: this.settings.windowX,
        windowY: this.settings.windowY,
        width: 500,
        height: 500,
        //游戏音量，介于[0, 100]之间。
        gain: 50,
        //连发键每秒频率 介于[5, 25]之间。
        turbo: 25,
        //玩家1
        p1: {
          UP: "KeyW",
          DOWN: "KeyS",
          LEFT: "KeyA",
          RIGHT: "KeyD",
          A: "KeyJ",
          B: "KeyK",
          C: "KeyU",
          D: "KeyI",
          SELECT: "Digit1",
          START: "Digit2",
        },
        //玩家2
        p2: {
          UP: "ArrowUp",
          DOWN: "ArrowDown",
          LEFT: "ArrowLeft",
          RIGHT: "ArrowRight",
          A: "Numpad1",
          B: "Numpad2",
          C: "Numpad4",
          D: "Numpad5",
        },
      };
    },
    //按钮事件
    menuEvent(type) {
      switch (type) {
        case "reset":
          {
            this.$refs.nes.reset();
            this.$toast.show("游戏已重启");
          }
          break;
        case "pause":
          {
            this.$refs.nes.pause();
            this.$toast.show("游戏已暂停");
          }
          break;
        case "play":
          {
            this.$refs.nes.play();
            this.$toast.show("游戏已恢复");
          }
          break;
        case "save":
          {
            this.$refs.nes.save("document_" + this.game.id);
            this.$toast.show("存档成功");
          }
          break;
        case "load":
          {
            this.$refs.nes.load("document_" + this.game.id);
            this.$toast.show("读档完成");
          }
          break;
        case "settings":
          {
            this.showSettings = true;
            this.$refs.nes.pause();
          }
          break;
        case "saveSettings":
          {
            this.saveConfig();
          }
          break;
      }
    },
    setKey(userNum, key, e) {
      if (this.compositionState) {
        e.preventDefault();
        return;
      }
      console.log(e);
      if (this.settings[`p${userNum}`]) {
        this.settings[`p${userNum}`][key] = e.code;
      }
      e.preventDefault();
    },
    //获取设置信息
    async getConfig() {
      let json = await this.$ws.send("game.getSettings", {});
      if (json == "" || json == "{}") {
        return;
      }
      json = JSON.parse(json);
      Object.assign(this.settings, json);
    },
    //保存设置
    async saveConfig(showMsg = false, resetWindow = false) {
      clearTimeout(this.saveTask);
      this.saveTask = setTimeout(async () => {
        try {
          let winInfo = await this.$ws.send("game.getWindowInfo", {});
          if (winInfo != "" && winInfo != null) {
            this.settings.windowWidth = winInfo.width;
            this.settings.windowHeight = winInfo.height;
            this.settings.windowX = winInfo.x;
            this.settings.windowY = winInfo.y;
          }
        } catch (ex) {
          console.log("获取窗口宽高失败", ex);
        }
        await this.$ws.send("game.saveSettings", {
          config: JSON.stringify(this.settings),
        });
      }, 200);
      if (showMsg) {
        this.$toast.show("保存成功");
      }
      if (!resetWindow) {
        this.showSettings = false;
        this.$refs.nes.play();
      }
    },
    //存档成功事件
    saved() {
      this.$toast.show("存档成功");
    },
    //读档后触发。
    loaded() {
      this.$toast.show("读档成功");
    },
    //删除存档后触发。
    removed() {
      this.$toast.show("删除存档成功");
    },
    //发生错误时触发，其中code是一个number 0：表示ROM读取错误  1：表示存档错误 2：表示读档错误 3：表示播放录像错误
    error({ code }) {
      if (code == 0) {
        this.$toast.show("ROM读取错误，请检查文件是否损坏！");
      } else if (code == 1) {
        this.$toast.show("存档错误，请检查文件是否损坏！");
      } else if (code == 2) {
        this.$toast.show("读档错误，请检查文件是否损坏！");
      } else if (code == 3) {
        this.$toast.show("播放录像错误!");
      }
    },
    //重置游戏宽高
    resetGameWindow() {
      if (this.$refs.nesContainer == null || isInitialResize) {
        if (isInitialResize) {
          isInitialResize = false;
        }
        return;
      }

      const rect = this.$refs.nesContainer.getBoundingClientRect();
      this.settings.width = rect.width;
      this.settings.height = rect.height;
      console.log("##");
      this.saveConfig(false, true);
    },
    //全局键盘事件
    globalKeyDown(e) {
      switch (e.keyCode) {
        case 112:
          this.menuEvent("pause");
          e.preventDefault();
          break;
        case 113:
          {
            this.menuEvent("play");
            e.preventDefault();
          }
          break;
        case 114:
          {
            this.menuEvent("save");
            e.preventDefault();
          }
          break;
        case 115:
          {
            this.menuEvent("load");
            e.preventDefault();
          }
          break;
        case 116:
          {
            this.menuEvent("reset");
            e.preventDefault();
          }
          break;
        case 119:
          {
            this.menuEvent("settings");
            e.preventDefault();
          }
          break;
        case 83:
          if (e.ctrlKey) {
            this.menuEvent("saveSettings");
            e.preventDefault();
            break;
          }
          break;
      }
    },
    //rom加载完成
    loadSuccess() {
      this.$ws.send("game.loadCompleted", {});
    },
  },
  mounted() {
    this.getConfig();
    this.game.file = this.$route.query.file;
    this.game.id = this.$route.query.id;
    this.game.cover = this.$route.query.cover;
    this.game.name = this.$route.query.name;

    let showTourDialog = localStorage.getItem("showTourDialog");
    if (showTourDialog == "" || showTourDialog == null) {
      this.showTourDialog = true;
    }

    this.resetGameWindow();
    window.addEventListener("resize", this.resetGameWindow);
    window.addEventListener("keydown", this.globalKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resetGameWindow);
    window.removeEventListener("keydown", this.globalKeyDown);
  },
  watch: {
    $route: {
      handler() {
        console.log(location.href);
        location.reload();
      },
    },
  },
};
</script>